import React, { Component, createContext } from 'react';

export type Language = 'en' | 'he' | 'ar'; // Define your supported languages here
interface LangProperties {
    dir: "ltr" | "rtl",
    title: string,
    font?: string, // TODO: implement
    dateFormat?: string // TODO: implement
}

export const langProperties:{[key in Language]: LangProperties} = {
    "en": {dir: "ltr", title: "English"},
    "he": {dir: "rtl", title: "Hebrew"},
    "ar": {dir: "rtl", title: "Arabic"}
}

export interface LanguageContextType {
    language: Language;
    setLanguage: (language: Language) => void; // Correctly define the setLanguage method
}

export const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const useLanguage = () => {
    const context = React.useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

interface LanguageProviderProps {
    children: React.ReactNode;
}

interface LanguageProviderState {
    language: Language;
}

class LanguageProvider extends Component<LanguageProviderProps, LanguageProviderState> {
    state: LanguageProviderState = {
        language: 'he', // Default language
    };

    componentDidMount() {
        const storedLanguage = localStorage.getItem('language') as Language | null;
        if (storedLanguage) {
            this.setState({ language: storedLanguage });
        }
    }

    setLanguage = (language: Language) => {
        this.setState({ language });
        localStorage.setItem('language', language);
    };

    render() {
        const { children } = this.props;
        const { language } = this.state;

        return (
            <LanguageContext.Provider value={{ language, setLanguage: this.setLanguage }}>
                {children}
            </LanguageContext.Provider>
        );
    }
}

export default LanguageProvider;
