import React, {createContext, Component, ReactNode, Context} from 'react';
import {incidentStatus} from "../../services/types/incidentStatus";
import {IIncident} from "../../services/types/incident";

export interface NotificationContextType {
    notifications: Notification[];
    addNotification: (incident: Notification) => void;
    removeNotification: (id: number) => void;
}

const NotificationContext: Context<NotificationContextType | undefined> = createContext<NotificationContextType | undefined>(undefined);

interface NotificationProviderProps {
    children: ReactNode;
}

export enum NOTIFICATION_TYPE {INCIDENT_ACTION, ERROR}
export enum INCIDENT_ACTIONS {STATUS_CHANGE, MERGE, SPLIT, SAVE}

interface INotification {
    id?: number,
    notificationType: NOTIFICATION_TYPE,
    actionType: INCIDENT_ACTIONS | null,
    title?: string,
    autoHideDuration?: number,
}

interface IErrorNotification extends INotification{
    notificationType: NOTIFICATION_TYPE.ERROR,
    actionType: null,
}

interface IIncidentNotification extends INotification {
    notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
    actionType: INCIDENT_ACTIONS,
    incidentIds: number[],
}

export interface IStatusChangeNotification extends IIncidentNotification{
    prevStatus: { [key: number]: incidentStatus },
    newStatus: { [key: number]: incidentStatus },
    actionType: INCIDENT_ACTIONS.STATUS_CHANGE,
    onUndo: (
        incidentIds: number[],
        undoneStatus: { [key:number]: incidentStatus },
        restoredStatus: { [key:number]: incidentStatus }
    ) => any,
}

export interface ISplitNotification extends IIncidentNotification {
    splitReports: number[]
    splitFrom: number,
    splitInto: number[],
    actionType: INCIDENT_ACTIONS.SPLIT
}

export interface IMergeNotification extends IIncidentNotification {
    mergedIncidents: number[],
    mergedInto: number,
    actionType: INCIDENT_ACTIONS.MERGE
}

export interface ISaveNotification extends IIncidentNotification {
    stateBefore: any,
    stateAfter: any,
    actionType: INCIDENT_ACTIONS.SAVE
}

export type IncidentNotification = (
    IStatusChangeNotification |
    ISplitNotification |
    IMergeNotification |
    ISaveNotification
    )

export type ErrorNotification = (
    IErrorNotification
    )

export type Notification = (
    IncidentNotification | ErrorNotification | null
)


class NotificationProvider extends Component<NotificationProviderProps, NotificationContextType> {
    constructor(props: NotificationProviderProps) {
        super(props);
        this.state = {
            notifications: [],
            addNotification: this.addNotification,
            removeNotification: this.removeNotification
        };
    }

    addNotification = (notification: Notification) => {
        if(!notification){return}
        notification.id = Date.now();
        this.setState((prevState) => ({
            notifications: [...prevState.notifications, notification]
        }));
    };

    removeNotification = (id: number) => {
        this.setState((prevState) => ({
            notifications: prevState.notifications.filter((notification:Notification) => {
                return notification && (notification.id !== id)
            })
        }));
    };

    render() {
        return (
            <NotificationContext.Provider value={this.state}>
                {this.props.children}
            </NotificationContext.Provider>
        );
    }
}

export {NotificationProvider, NotificationContext};
