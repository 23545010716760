import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";
import IncidentViewer from "./incident/IncidentViewer";
import {
    curationToBrowseWorkspace, encodeBrowseWorkspaceSearchParams,
    encodeCurationSessionWorkspaceSearchParams, IBrowseWorkspace,
    ICurationSessionWorkspace,
    parseCurationSessionWorkspaceSearchParams
} from "../services/types/workspace";
import {IconButton, Stack} from "@mui/material";
import {Link} from "@mui/icons-material";
import IncidentPermalinkGetter from "./incident/IncidentPermalinkGetter";

interface IProps extends IRouterProps {}
interface IState {
    initWorkspace: ICurationSessionWorkspace
}

class Incident extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const workspaceParamStr = props.searchParams.get("s");
        this.state = {
            initWorkspace: parseCurationSessionWorkspaceSearchParams(workspaceParamStr)
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        // TODO: fix URL changes not affecting incident viewer
        const workspaceParamStr = this.props.searchParams.get("s");
        const newInitWorkspace = parseCurationSessionWorkspaceSearchParams(workspaceParamStr)
        if(this.state.initWorkspace.incidentId !== newInitWorkspace.incidentId){
            this.setState((curr)=>({...curr, initWorkspace: newInitWorkspace}))
        }
    }

    private goToIncidentsPage = (workspace:ICurationSessionWorkspace) => {
        const workspaceParam = encodeBrowseWorkspaceSearchParams(curationToBrowseWorkspace(workspace));
        this.props.navigate({
            pathname: "/incidents/",
            search: "s="+workspaceParam,
        });
    }

    private setWorkspaceConfig = (workspace: ICurationSessionWorkspace) => {
        const newUrl = `${window.location.pathname}?s=${encodeCurationSessionWorkspaceSearchParams(workspace)}`;

        const currentParams = decodeURIComponent(new URLSearchParams(window.location.search).get('s') || "");
        const newEncodedSParam = encodeCurationSessionWorkspaceSearchParams(workspace);

        if(currentParams !== newEncodedSParam) {
            window.history.pushState({}, '', newUrl);
        }
    }

    render() {
        const initWorkspace = this.state.initWorkspace;
        const incidentId:number | undefined = initWorkspace.incidentId;
        return <div className={"page-wrap"}>
            <TopNavBar>
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                <div className="title-wrap">
                    פרטי תקרית
                </div>
                    <IncidentPermalinkGetter incidentId={incidentId}/>
                </Stack>
            </TopNavBar>
            <div className={"page-content content-wrap"} dir={"rtl"}>
                <IncidentViewer
                    workspaceConfig={this.state.initWorkspace}
                    onWorkspaceChange={(config)=>{
                        this.setWorkspaceConfig(config);
                    }}
                    goToBrowse={this.goToIncidentsPage}
                />
            </div>
        </div>
    }
}

export default (withRouter(Incident));