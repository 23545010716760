import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";

import {IconButton, Stack} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SourceManager from "./sources/SourceManager";
import AlignDir from "../services/languages/AlignDir";

interface IProps extends IRouterProps {}
interface IState {
    sourceId: number | null
}

class SourceDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const sourceIdArg = props.searchParams.get("id");
        const sourceId = sourceIdArg === null ? null : parseInt(sourceIdArg);
        this.state = {
            sourceId
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const sourceIdArg = this.props.searchParams.get("id");
        const sourceId = sourceIdArg === null ? null : parseInt(sourceIdArg);
        if(this.state.sourceId !== sourceId){
            this.setState((curr)=>({...curr, sourceId}))
        }
    }

    private goToSourcesPage = () => {
        this.props.navigate({
            pathname: "/sources/",
        });
    }

    render() {
        const sourceId = this.state.sourceId;
        return <div className={"page-wrap"}>
            <TopNavBar>
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <IconButton
                        color={"primary"}
                        onClick={()=>{
                            this.goToSourcesPage()
                        }}
                    >
                        <ArrowForwardIcon/>
                    </IconButton>
                <div className="title-wrap">
                    הגדרות מקור
                </div>
                </Stack>
            </TopNavBar>
            <AlignDir direction={"ltr"}>
            <div className={"page-content content-wrap"}>
                {
                    sourceId === null ?
                        <div>יש לבחור במקור</div> :
                        <SourceManager id={sourceId}/>
                }
            </div>
            </AlignDir>
        </div>
    }
}

export default (withRouter(SourceDetails));