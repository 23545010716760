import React from 'react'
import {Stack} from "@mui/material";
import {
    IColumnArray,
} from "../../../services/types/columns";
import BaseFieldDefinition, {IIncidentFieldProps} from "./BaseFieldDefinition";
import FieldDefinition from "./FieldDefinition";

export default class FieldDefinitionArray extends BaseFieldDefinition<IColumnArray> {
    constructor(props: IIncidentFieldProps<IColumnArray>) {
        super(props);
    }

    render() {
        const column = this.props.column;
        const onEdit = this.props.onEdit;

        return <Stack direction={"column"} gap={1}>
            <span>Each Entry:</span>
                <FieldDefinition
                    column={column.entries}
                    onEdit={onEdit ? (v) => {
                        column.entries = v;
                        if (onEdit) {
                            onEdit(column)
                        }
                    } : null}
                    depth={this.props.depth + 1}
                    isArrayEntry={true}
                />
        </Stack>
    }
}