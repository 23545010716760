import React from 'react';
import { NotificationContext, NotificationContextType } from './NotificationsContext';

export interface WithNotificationProps {
    notificationContext: NotificationContextType;
}

const withNotification = <P extends WithNotificationProps>(
    WrappedComponent: React.ComponentType<P>
) => {
    return class WithNotification extends React.Component<Omit<P, keyof WithNotificationProps>, any> {
        render() {
            return (
                <NotificationContext.Consumer>
                    {context => {
                        if (!context) {
                            throw new Error('NotificationContext not found');
                        }
                        return <WrappedComponent {...(this.props as P)} notificationContext={context} />;
                    }}
                </NotificationContext.Consumer>
            );
        }
    };
};

export default withNotification;
