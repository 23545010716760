import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";
import server from "../services/server";
import "./settings/settings.scss"
import {
    Accordion, AccordionDetails, AccordionSummary,
    Button,
    CircularProgress, FormControlLabel,
    IconButton,
    Snackbar,
    Stack,
    styled, Switch,
    TextField,
    Tooltip, tooltipClasses,
    TooltipProps,
    Zoom
} from "@mui/material";
import {AddCircle, RemoveCircle, ExpandMore} from "@mui/icons-material";

interface IProps extends IRouterProps {}
type DataItem = ({[key: string]: any}&{id: number | null, _changed:boolean})
interface IState {
    loadingData: boolean,
    loadingError: string | null,
    data: DataItem[],
    deletedRows: number[],
    awaitingSave: boolean,
    saveNotification: string | null,

}

type DataFieldType = "bool" | "str" | "number"

interface IDataField {
    title: string,
    key: string,
    type: DataFieldType,
    disabled?: boolean
}

const DataFieldRenderer: {[key in DataFieldType]: (f: IDataField, value: any, onChange:(value: any)=>any)=>JSX.Element} = {
    "str": (f, value, onChange)=><TextField
        disabled={!!f.disabled}
        label={f.title}
        key={f.key}
        value={value || ""}
        variant="outlined"
        placeholder={f.title}
        onChange={(e)=>{
            onChange(e.target.value);
        }}
    />,
    "number": (f, value, onChange)=><TextField
        disabled={!!f.disabled}
        label={f.title}
        key={f.key}
        value={value || ""}
        variant="outlined"
        placeholder={f.title}
        onChange={(e)=>{
            onChange(e.target.value);
        }}
    />,
    "bool": (f, value, onChange)=><FormControlLabel key={f.key} control={
        <Switch
            disabled={!!f.disabled}
            checked={!!value}
            onChange={(e)=>{
                onChange(e.target.checked);
            }}
        />
    } label={f.title}/>
}

const HtmlTooltip = styled(({ className, children,...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}  children={children}/>
))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
}));

class InclusionKeywords extends React.Component<IProps, IState> {

    private endPoint = "exclusion-keywords";
    private fields: IDataField[] = [
        {title: "keyword", key: "keyword", type: "str"},
        {title: "stemmed", key: "stemmed", type: "str", disabled: true},
        {title: "exact", key: "exact", type: "bool"},
        {title: "regex", key: "regex", type: "bool"},
    ];
    private defaultNewItem: {[key: string]: any} = {
        keyword: "",
        stemmed: "",
        exact: false,
        regex: false
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            loadingData: false,
            loadingError: null,
            data: [],
            deletedRows: [],
            awaitingSave: false,
            saveNotification: null
        }
    }

    async componentDidMount(){
        await this.fetchData();
    }

    private async fetchData() {
        if(this.state.loadingData){return}
        this.setState((curr)=>({...curr, loadingData: true}),async ()=>{
            const res = await server.get(this.endPoint + "/");
            if (res && !res.error) {
                const nullDataFlags:DataItem[] = res.map(
                    (x:{[key: string]: any}&{id: number | null}) => {
                    const item:DataItem = {...x, _changed: false};
                    return item;
                });
                this.setState((curr)=>({
                    ...curr, data: nullDataFlags, deletedRows: [],
                    loadingData: false, loadingError: null
                }))
            }
            else {
                this.setState((curr)=>({
                    ...curr, loadingData: false,
                    loadingError: res?.error || "error - failed to load data"
                }))
            }
        })
    }

    private editData(row: number, field: string, value: any){
        const data = this.state.data.slice();
        if(data.length < row || row < 0){return}
        data[row][field] = value;
        data[row]._changed = true;
        this.setState((curr)=>({...curr, data}))
    }

    private addRow() {
        const data = this.state.data.slice();
        data.push({
            ...JSON.parse(JSON.stringify(this.defaultNewItem)),
            id: null,
            _changed: true
        })
        this.setState((curr)=>({...curr, data}))
    }

    private deleteData(rowIndex: number){
        const data = this.state.data.slice();
        if(rowIndex < 0 || rowIndex >= data.length){return}
        const row = data[rowIndex];
        const rowId = row?.id;
        const deletedRows = this.state.deletedRows.slice();
        if(rowId && !(rowId in deletedRows)) {
            deletedRows.push(rowId);
        }
        data.splice(rowIndex, 1);
        this.setState((curr)=>({...curr, deletedRows, data}))
    }

    private async saveData(){
        if(this.state.awaitingSave){return}
        this.setState((curr)=>({...curr, awaitingSave: true}), async ()=>{
            const data = this.state.data.slice().filter(x=>x._changed);
            const deletedRows = this.state.deletedRows.slice();
            const res = await server.post(this.endPoint + "/",
                {data: data, deleted_rows: deletedRows}
            );
            if (res.success) {
                this.setState((curr)=>({
                    ...curr,
                    awaitingSave: false, saveNotification: "data saved",
                }), ()=>{
                    this.fetchData();
                })
            }
            else {
                this.setState((curr)=>({
                    ...curr, awaitingSave: false,
                    saveNotification: res?.error || "error - couldn't save data"
                }))
            }
        });
    }

    private getSaveNotifications(){
        const msg = this.state.saveNotification;
        return <Snackbar
            open={msg !== null}
            autoHideDuration={3000}
            onClose={()=>{this.setState((curr)=>(
                {...curr, saveNotification: null, awaitingSave: false}
            ))}}
            message={msg}
        />
    }

    private getDataEditor():JSX.Element {
        const data = this.state.data.slice();
        return <Stack direction={"column"} gap={1} className={"settings-editor"}>
            {
                data.map((r, i)=>{
                    return <React.Fragment key={i}>
                        <HtmlTooltip
                            TransitionComponent={Zoom}
                            TransitionProps={
                                {
                                    style: {
                                        transformOrigin: "center left"
                                    }
                                }
                            }
                            title={
                                <Tooltip title={"הסרת רשומה"} arrow placement={"bottom"}>
                                    <IconButton
                                        aria-label="delete"
                                        color={"error"}
                                        onClick={()=> {this.deleteData(i)}}
                                    >
                                        <RemoveCircle/>
                                    </IconButton>
                                </Tooltip>
                            }
                            placement={"right-start"}
                            key={i}
                        >
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    {data[i].keyword || "new keyword"}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    {
                                        this.fields.map((f, j)=>{
                                            return DataFieldRenderer[f.type](
                                                f,
                                                data[i][f.key],
                                                (value)=>{this.editData(i, f.key, value);}
                                            )
                                        })
                                    }
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </HtmlTooltip>
                    </React.Fragment>
                })
            }
            <div><Tooltip title={"הוספת רשומה"} arrow placement={"bottom"}>
                <IconButton
                    aria-label="add"
                    color={"primary"}
                    onClick={()=>{this.addRow()}}
                >
                    <AddCircle/>
                </IconButton>
            </Tooltip></div>
        </Stack>
    }

    render() {
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    כללי התעלמות
                </div>
            </TopNavBar>
            <div className={"page-content content-wrap"}>
                {
                    this.state.loadingData ? <CircularProgress/> : (
                        this.state.loadingError ? <span className={"data-load-error"}>
                            {this.state.loadingError}
                        </span> : <span>
                            {this.getDataEditor()}
                            <div className={"save-section-wrap"}>
                            {
                                this.state.awaitingSave ?
                                    <CircularProgress/> :
                                    <Button
                                        onClick={async ()=>{
                                            await this.saveData();
                                        }}
                                        variant={"outlined"}
                                    >
                                        Save
                                    </Button>
                            }
                            </div>
                        </span>
                    )
                }
                {this.getSaveNotifications()}
            </div>
        </div>
    }
}

export default (withRouter(InclusionKeywords));