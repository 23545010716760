import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";
import server from "../services/server";
import "./settings/settings.scss"
import {
    Button,
    CircularProgress, Dialog, DialogContent, DialogTitle,
    Snackbar, Stack,
} from "@mui/material";
import {IColumnArray, IColumnObject} from "../services/types/columns";
import FieldDefinition from "./columns/fields/FieldDefinition";
import AlignDir from "../services/languages/AlignDir";

interface IProps extends IRouterProps {}
interface IState {
    loadingData: boolean,
    loadingError: string | null,
    columns: IColumnObject | null,
    awaitingSave: boolean,
    saveNotification: string | null,
    promptPreview: string | null
}

class ColumnDefinitions extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loadingData: false,
            loadingError: null,
            columns: null,
            awaitingSave: false,
            saveNotification: null,
            promptPreview: null
        }
    }

    async componentDidMount(){
        await this.fetchData();
    }

    private async fetchData() {
        if(this.state.loadingData){return}
        const res = await server.get("columns/");
        if (res && !res.error) {
            const columns = (((res as IColumnObject).properties[0] as IColumnArray).entries as IColumnObject);
            return await new Promise<void>((resolve) => {
                this.setState((current) => ({...current, columns}), () => {
                    resolve()
                })
            })
        }
    }

    private async fetchPrompt() {
        const columns = this.state.columns;
        const promptPreview = await server.post("columns/prompt_preview/", {columns: JSON.stringify(columns)});
        this.setState((curr)=>({...curr, promptPreview}))
    }

    private async recalcIndices() {
        const promptPreview = await server.get("scrape/index/");
        console.log("done!")
    }

    private async saveData(){
        if(this.state.awaitingSave || !this.state.columns){return}
        this.setState((curr)=>({...curr, awaitingSave: true}), async ()=>{
            const columns = this.state.columns;
            const res = await server.post("columns/",
                {columns: JSON.stringify(columns)}
            );
            if (res.success) {
                this.setState((curr)=>({
                    ...curr,
                    awaitingSave: false, saveNotification: "data saved",
                }), ()=>{
                    this.fetchData();
                })
            }
            else {
                this.setState((curr)=>({
                    ...curr, awaitingSave: false,
                    saveNotification: res?.error || "error - couldn't save data"
                }))
            }
        });
    }

    private getSaveNotifications(){
        const msg = this.state.saveNotification;
        return <Snackbar
            open={msg !== null}
            autoHideDuration={3000}
            onClose={()=>{this.setState((curr)=>(
                {...curr, saveNotification: null, awaitingSave: false}
            ))}}
            message={msg}
        />
    }

    private getPromptPreview() {
        return <Dialog
            open={!!this.state.promptPreview}
            onClose={()=>{this.setState((curr)=>({...curr, promptPreview: null}))}}
            PaperProps={{
                sx: {
                    maxHeight: "80vh"
                }
            }}
        >
            <DialogTitle>Prompt Preview</DialogTitle>
            <DialogContent>{
                (this.state.promptPreview || "").split("\n").map((p: string, i: number)=>{
                    return <p key={i}>{p}</p>
                })
            }</DialogContent>
        </Dialog>
    }

    render() {
        const columns = this.state.columns;
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    הגדרת עמודות
                </div>
            </TopNavBar>
            <div className={"page-content content-wrap"}>
                <AlignDir direction={"ltr"}>
                {
                    (this.state.loadingData || columns === null) ? <CircularProgress/> : (
                        this.state.loadingError ? <span className={"data-load-error"}>
                            {this.state.loadingError}
                        </span> : <span>
                            <FieldDefinition
                                column={columns}
                                onEdit={
                                    (c)=>{
                                        this.setState((curr)=>({...curr, columns: c}))
                                    }
                                }
                                depth={0}
                            />
                            <Stack direction={"row"} gap={2} className={"save-section-wrap"}>
                            {
                                this.state.awaitingSave ?
                                    <CircularProgress/> :
                                    <Button
                                        onClick={async () => {
                                            await this.saveData();
                                        }}
                                        variant={"outlined"}
                                    >
                                        Save
                                    </Button>
                            }
                                <Button
                                    onClick={async () => {
                                        await this.fetchPrompt();
                                    }}
                                    variant={"outlined"}
                                >
                                    Preview GPT Prompt
                                </Button>
                                <Button
                                    onClick={async () => {
                                        await this.recalcIndices();
                                    }}
                                    variant={"outlined"}
                                >
                                    Recalculate Indices
                                </Button>
                            </Stack>
                        </span>
                    )
                }
                {this.getSaveNotifications()}
                {this.getPromptPreview()}
                </AlignDir>
            </div>
        </div>
    }
}

export default (withRouter(ColumnDefinitions));