import React from 'react'
import {IColumnDate} from "../../../services/types/columns";
import BaseFieldDefinition, {IIncidentFieldProps} from "./BaseFieldDefinition";

export default class FieldDefinitionDate extends BaseFieldDefinition<IColumnDate> {
    constructor(props:IIncidentFieldProps<IColumnDate>) {
        super(props);
    }

    render() {
        return <React.Fragment/>
    }
}