import React from 'react'
import { FormControl } from "@mui/material";
import {IColumnDate} from "../../../services/types/columns";
import BaseIncidentField from "./BaseIncidentField";
import {cn} from "../../../services/utils";
import dayjs from "dayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

export default class IncidentFieldDate extends BaseIncidentField<IColumnDate> {
    render() {
        const column = this.props.column;
        const data = this.props.data;
        const emptyChecker = this.props.emptyChecker;
        const sources = this.props.sources;
        const onSourcesClick = this.props.onSourcesClick;
        const onEdit = this.props.onEdit;
        const onClick = this.props.onClick;
        const value = this.props.emptyChecker(data) ? null : dayjs(data);
        const labelOverride = this.props.labelOverride

        return <div className={cn({
            "empty-data": emptyChecker(data),
            "card-leaf-data-wrap": true
        })}
                    onClick={()=>{
                        if(onClick){
                            onClick(data);
                        }
                    }}>
            <FormControl className={"fill-width-util"}>
                <div className={"sources-adornment"}>
                    {this.getLabelSourcesAdornment(column, sources, onSourcesClick)}
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className={"fill-width-util"}
                        label={labelOverride !== undefined ? labelOverride : column.title}
                        value={value}
                        views={['year', 'month', 'day']}
                        format={"DD/MM/YYYY"}
                        readOnly ={!onEdit}
                        onChange={(v)=>{
                            if(onEdit){
                                onEdit(v, null);
                            }
                        }}
                    />
                </LocalizationProvider>
            </FormControl>
        </div>
    }
}