import React from 'react'
import {
    IColumn, IColumnArray,
    IColumnCity,
    IColumnDate,
    IColumnNumber, IColumnObject,
    IColumnSelect,
    IColumnText
} from "../../../services/types/columns";
import BaseIncidentField from "./BaseIncidentField";
import IncidentFieldText from "./IncidentFieldText";
import IncidentFieldCity from "./IncidentFieldCity";
import IncidentFieldNumber from "./IncidentFieldNumber";
import IncidentFieldSelect from "./IncidentFieldSelect";
import IncidentFieldDate from "./IncidentFieldDate";
import IncidentFieldObject from "./IncidentFieldObject";
import IncidentFieldArray from "./IncidentFieldArray";

export default class IncidentField extends BaseIncidentField<IColumn> {
    render() {
        const columnType = this.props.column.column_type;
        switch (columnType){
            case "TEXT":
                return <IncidentFieldText {...this.props} column={(this.props.column as IColumnText)}/>
            case "NUMBER":
                return <IncidentFieldNumber {...this.props} column={(this.props.column as IColumnNumber)}/>
            case "CITY":
                return <IncidentFieldCity {...this.props} column={(this.props.column as IColumnCity)}/>
            case "SELECT":
                return <IncidentFieldSelect {...this.props} column={(this.props.column as IColumnSelect)}/>
            case "DATE":
                return <IncidentFieldDate {...this.props} column={(this.props.column as IColumnDate)}/>
            case "OBJECT":
                return <IncidentFieldObject {...this.props} column={(this.props.column as IColumnObject)}/>
            case "ARRAY":
                return <IncidentFieldArray {...this.props} column={(this.props.column as IColumnArray)}/>
        }
        return <div>missing renderer</div>
    }
}