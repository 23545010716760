import React from 'react'
import {
    FormControl,
    IconButton,
    Stack, styled,
    TextField,
    Tooltip, tooltipClasses, TooltipProps,
    Zoom
} from "@mui/material";
import {IColumnSelect} from "../../../services/types/columns";
import BaseFieldDefinition, {IIncidentFieldProps} from "./BaseFieldDefinition";
import {AddCircle, RemoveCircle} from "@mui/icons-material";

const HtmlTooltip = styled(({ className, children,...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}  children={children}/>
))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
}));

export default class FieldDefinitionSelect extends BaseFieldDefinition<IColumnSelect> {
    constructor(props:IIncidentFieldProps<IColumnSelect>) {
        super(props);
    }

    render() {
        const column = this.props.column;
        const onEdit = this.props.onEdit;
        const options = column.options;
        return <Stack direction={"column"} gap={1}>
            <span>Available Options:</span>
            {
                <Stack gap={1}>
                    {
                        options.map((e: any, i: number) => {
                            return <HtmlTooltip
                                TransitionComponent={Zoom}
                                TransitionProps={
                                    {
                                        style: {
                                            transformOrigin: "center left"
                                        }
                                    }
                                }
                                title={
                                    !!onEdit ? <Tooltip title={"הסרת אפשרות"} arrow placement={"bottom"}>
                                        <IconButton
                                            aria-label="delete"
                                            color={"error"}
                                            onClick={()=> {
                                                if(onEdit){
                                                    column.options.splice(i, 1);
                                                    onEdit(column);
                                                }
                                            }}
                                        >
                                            <RemoveCircle/>
                                        </IconButton>
                                    </Tooltip> : ""
                                }
                                placement={"right-start"}
                                key={i}
                            >
                                <FormControl className={"fill-width-util"}>
                                    <TextField
                                        label={"Language (English)"}
                                        className={"fill-width-util"}
                                        variant="outlined"
                                        value={column.options[i]}
                                        InputProps={{
                                            readOnly: !onEdit,
                                        }}
                                        onChange={(e)=>{
                                            if(onEdit){
                                                column.options[i] = e.target.value
                                                onEdit(column);
                                            }
                                        }}
                                    />
                                </FormControl>
                            </HtmlTooltip>
                        })
                    }
                    {
                        !!this.props.onEdit ?
                            <div><Tooltip title={"הוספת רשומה"} arrow placement={"bottom"}>
                                <IconButton
                                    aria-label="add"
                                    color={"primary"}
                                    onClick={()=>{
                                        if(onEdit){
                                            column.options.push("");
                                            onEdit(column);
                                        }
                                    }}
                                >
                                    <AddCircle/>
                                </IconButton>
                            </Tooltip></div> : null
                    }
                </Stack>
            }
        </Stack>
    }
}