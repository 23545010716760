import React from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// Define the types for the filter props
interface DateFilterProps {
    column: {
        filterValue: string | undefined;
        setFilterValue: (value: string | undefined) => void;
    };
}

const DateFilter: React.FC<DateFilterProps> = ({ column: { filterValue, setFilterValue } }) => {
    const handleDateChange = (newValue: Dayjs | null) => {
        const utcDate = newValue ? dayjs.utc(newValue).startOf('day') : null;
        setFilterValue(utcDate ? newValue?.format() : undefined);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={filterValue ? dayjs(filterValue) : null}
                onChange={handleDateChange}
                views={['year', 'month', 'day']}
                format={"DD/MM/YYYY"}
            />
        </LocalizationProvider>
    );
};

export default DateFilter;
