import React from 'react'
import {IColumn, IColumnArray, IColumnObject} from "../../services/types/columns";
import {Divider, Stack} from "@mui/material";

export interface IIncidentInfoProps {
    column: IColumn,
    data: any,
    emptyChecker: (v:any) => boolean,
}
interface IState {}


export default class IncidentInfoCard extends React.Component<IIncidentInfoProps, IState> {
    constructor(props: IIncidentInfoProps) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {}

    render() {
        const data = this.props.data;
        const column = this.props.column;
        if(column.column_type === "OBJECT"){
            const columnAsObject = column as IColumnObject;
            return <Stack
                gap={1}
                direction={"column"}
                divider={<Divider orientation={"horizontal"}/>}
                sx={{width: "100%"}}
            >
                {columnAsObject.properties.map((p, i)=>{
                    return <Stack gap={2} direction={"row"} key={i}>
                        <b>{p.title}:</b>
                        <span style={{flexGrow: "1"}}>
                            <IncidentInfoCard
                                column={p}
                                data={data[p.key]}
                                emptyChecker={this.props.emptyChecker}
                            />
                        </span>
                    </Stack>
                })}
            </Stack>
        }
        if(column.column_type === "ARRAY"){
            const dataAsArr = Array.isArray(data) ? data : []
            return <Stack gap={1} direction={"column"} divider={<Divider orientation={"horizontal"}/>}>
                {dataAsArr.map((v, i)=>{
                    return <IncidentInfoCard
                        key={i}
                        column={(column as IColumnArray).entries}
                        data={v}
                        emptyChecker={this.props.emptyChecker}
                    />
                })}
            </Stack>
        }
        else{
            return <span>{data || ""}</span>
        }
    }
}