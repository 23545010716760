import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    LiteralUnion,
    MaterialReactTable,
    MRT_ColumnDef,
    MRT_ColumnFilterFnsState,
    MRT_ColumnFiltersState,
    MRT_ColumnOrderState,
    MRT_ColumnPinningState,
    MRT_ColumnSizingState,
    MRT_FilterOption,
    MRT_PaginationState,
    MRT_Row,
    MRT_RowSelectionState,
    MRT_SortingState,
    MRT_TableInstance,
    MRT_TableOptions,
    MRT_VisibilityState,
    useMaterialReactTable,
} from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import MergeIcon from '@mui/icons-material/MergeType';
import {IColumn, IColumnObject, IColumnSelect, TColumnTypes} from "../../services/types/columns";
import {IIncident, IIncidentReport} from "../../services/types/incident";
import {getColDefaultWidth, incidentColFilters, incidentColRenderers} from "./columnRenderers";
import {Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Tooltip} from "@mui/material";
import server from "../../services/server";
import {mergeIncidents, setIncidentApprovalStatus, splitIncidentReportWithId} from "../../services/incidentManager";
import {incidentStatus, incidentStatusOptions, incidentStatusProperties} from "../../services/types/incidentStatus";
import {IBrowseWorkspace} from "../../services/types/workspace";
import {
    ErrorNotification,
    IMergeNotification,
    INCIDENT_ACTIONS, ISplitNotification,
    IStatusChangeNotification,
    NOTIFICATION_TYPE,
} from "../../UIComponents/IncidentNotifications/NotificationsContext";
import withNotification, {WithNotificationProps} from "../../UIComponents/IncidentNotifications/withNotifications";


interface IProps {
    columnSchema: IColumnObject,
    emptyDataIndicators: string[],
    onClick?: ((incidentId:number, workspace:IBrowseWorkspace)=>any) | null,
    initWorkspace?: IBrowseWorkspace,
    onWorkspaceChange?: (workspace:IBrowseWorkspace)=>any,
    permanentlySelectedRows?: number[],
    onMerge?: (mergedInto: number, merged: number[])=>any
}
export const DEFAULT_INCIDENTS_PER_PAGE =  10;
const DATA_ACCESSOR_PREFIX = "data.";

type Props = IProps & WithNotificationProps;

const IncidentTable: React.FC<Props> = (props:Props) => {
    const [columns] = useState<IColumn[]>(props.columnSchema?.properties?.slice() || [])

    //data and fetching state
    const [data, setData] = useState<(IIncident | IIncidentReport)[]>([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);

    //table state
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        props.initWorkspace?.columnFilters || [],
    );

    const defaultFilterFns: {[key: string]: LiteralUnion<string & MRT_FilterOption>} = {};
    columns.forEach((c)=>{
        const defaultFn = incidentColFilters[c.column_type as TColumnTypes].columnFilterModeOptions?.[0]
        if(defaultFn){
            defaultFilterFns[DATA_ACCESSOR_PREFIX + c.key] = defaultFn;
        }
    })
    const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
        {...defaultFilterFns,...props.initWorkspace?.columnFilterFns},
    );
    const [globalFilter, setGlobalFilter] = useState<string>(props.initWorkspace?.globalFilter || '');
    const [sorting, setSorting] = useState<MRT_SortingState>(props.initWorkspace?.sorting || []);

    const page_size_pref = localStorage.getItem("incident_table_page_size")
    const initPagination:MRT_PaginationState = props.initWorkspace?.pagination || {
        pageIndex: 0,
        pageSize: page_size_pref ? parseInt(page_size_pref) : DEFAULT_INCIDENTS_PER_PAGE,
    }
    const [rowCount, setRowCount] = useState(initPagination.pageSize * (initPagination.pageIndex + 1));
    const [pagination, setPagination] = useState<MRT_PaginationState>(initPagination);
    // Selection state
    const initSelection:{[key: string]: boolean} = {};
    props.permanentlySelectedRows?.forEach(id=> {
        initSelection["id_"+id] = true;
    })

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(initSelection);


    const tablePreferences = JSON.parse(
        localStorage.getItem("incident_table_preferences") || "{}"
    );
    const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
        tablePreferences.columnOrder || ['mrt-row-select', ...columns.map((c) => c.key)]
    );
    const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>(
        {
            left: ["mrt-row-actions", "mrt-row-expand", "mrt-row-select", ...(tablePreferences?.columnPinning?.left || [])]
                .filter((value, index, array) => array.indexOf(value) === index),
            right: [...(tablePreferences?.columnPinning?.right || [])]
        }
    );
    const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(
        tablePreferences.columnSizing || {}
    );
    const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
        tablePreferences.columnVisibility || {}
    );

    useEffect(() => {
        const preferences = JSON.stringify({
            columnSizing,
            columnOrder,
            columnPinning,
            columnVisibility
        })
        localStorage.setItem("incident_table_preferences", preferences)
    }, [
        columnSizing,
        columnOrder,
        columnPinning,
        columnVisibility,
    ])

    const nonDefaultFilterFunction = (columnFilterFns: MRT_ColumnFilterFnsState):MRT_ColumnFilterFnsState => {
        const nonDefaultFilterFns = JSON.parse(JSON.stringify(columnFilterFns)) as MRT_ColumnFilterFnsState;

        columns.forEach(c=>{
            if(nonDefaultFilterFns[DATA_ACCESSOR_PREFIX + c.key] === incidentColFilters[c.column_type as TColumnTypes].columnFilterModeOptions?.[0]){
                delete nonDefaultFilterFns[DATA_ACCESSOR_PREFIX + c.key]
            }
        })
        return nonDefaultFilterFns;
    }

    const fetchData = useCallback(async () => {
        if (!data.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        const workspace:IBrowseWorkspace = {
            columnFilters,
            columnFilterFns,
            globalFilter,
            pagination,
            sorting,
            status: (columnFilters.filter(f => f.id === "approved")[0]?.value as incidentStatus[] | null)
        }
        const res = await server.post("workspace/incidents/", workspace);
        try {
            if(res.page !== pagination.pageIndex){return}
            if(props.onWorkspaceChange){
                workspace.columnFilterFns = nonDefaultFilterFunction(columnFilterFns);
                props.onWorkspaceChange(workspace);
            }
            const incidents:IIncident[] = res.incidents.map((incident:any) => {
                try {
                    incident.data = JSON.parse(incident.data);
                    incident.sources = JSON.parse(incident.sources);
                    incident.sources = incident.sources.sort((a: IIncidentReport, b: IIncidentReport)=>(a.id - b.id))
                    return  incident;
                } catch (e) {
                    console.log(e)
                    return null;
                }
            }).filter((i:null | IIncident)=>!!i);
            setData(incidents.slice());
            setRowCount(res.rowCount);
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    }, [
        columnFilters,
        columnFilterFns,
        globalFilter,
        sorting,
        props,
        data,
        pagination
    ])

    useEffect( () => {
        fetchData().then(()=>{});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        columnFilters, //re-fetch when column filters change
        columnFilterFns, //re-fetch when column filter mode changes
        globalFilter, //re-fetch when global filter changes
        pagination.pageIndex, //re-fetch when page index changes
        pagination.pageSize, //re-fetch when page size changes
        sorting, //re-fetch when sorting changes
    ])


    useEffect( () => {
         localStorage.setItem("incident_table_page_size", "" + pagination.pageSize)
        console.log("setting preferred page size to")
        console.log(localStorage.getItem("incident_table_page_size"))
    }, [
        pagination.pageSize
    ])


    useEffect(() => {
        const columnFilterProps = props.initWorkspace?.columnFilters;
        if(columnFilterProps && (columnFilterProps !== columnFilters)){
            setColumnFilters(columnFilterProps)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.initWorkspace?.columnFilters,
        props.initWorkspace?.globalFilter,
        props.initWorkspace?.sorting,
        props.initWorkspace?.pagination.pageIndex,
        props.initWorkspace?.pagination.pageSize,
        props.initWorkspace?.status,
        props.initWorkspace?.columnFilterFns,
    ]);

    async function changeIncidentsApprovalStatus(incidents: IIncident[], status: incidentStatus) {
        const incidentIds = incidents.map(i=>i.id);
        const prevStatus: {[key: number]: incidentStatus} = {}
        const setTo: {[key: number]: incidentStatus} = {}
        incidents.forEach(i=> {
            prevStatus[i.id] = i.approved;
            setTo[i.id] = status
        })
        await setIncidentApprovalStatus(setTo)
        const notification: IStatusChangeNotification = {
            incidentIds: incidentIds,
            prevStatus: prevStatus,
            newStatus: setTo,
            notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
            actionType: INCIDENT_ACTIONS.STATUS_CHANGE,
            title: "סטטוס התקרית שונה",
            onUndo: async () => {
                if (fetchData) {
                    await fetchData()
                }
            }
        };
        props.notificationContext.addNotification(notification)
        if (fetchData) {
            await fetchData()
        }
    }

    const tableColumns: MRT_ColumnDef<IIncident|IIncidentReport>[] = useMemo(()=> {
        const columnDefs: MRT_ColumnDef<IIncident|IIncidentReport>[] = [];
        columnDefs.push({
            id: "approved",
            header: "Status",
            accessorKey: "approved",
            Cell: ({renderedCellValue, row}) => {
                return renderedCellValue ? <Tooltip
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: "black",
                                backgroundColor: "white",
                                boxShadow: "1px 1px 3px #00000040"
                            }
                        },
                    }}
                    title={<Stack direction={"column"} alignItems={"center"} gap={1}>
                        <span>
                            {incidentStatusProperties[(renderedCellValue as incidentStatus)].title}
                        </span>
                        <Stack direction={"row"} justifyContent={"center"}>
                            {incidentStatusProperties[(renderedCellValue as incidentStatus)]
                                .availableActionsFromTableView.map((action)=>{
                                return <Tooltip
                                    title={action.title}
                                    key={action.title}
                                >
                                    <IconButton
                                        onClick={async () => {
                                            await changeIncidentsApprovalStatus([row.original as IIncident], action.setTo);
                                        }}
                                    >
                                        {action.icon}
                                    </IconButton>
                                </Tooltip>
                            })}
                        </Stack>
                    </Stack>}>
                    {incidentStatusProperties[(renderedCellValue as incidentStatus)].icon}
                </Tooltip> : null
            },
            size: 90,
            filterVariant: 'multi-select',
            filterSelectOptions: incidentStatusOptions.map(s=>({
                label: incidentStatusProperties[s].title,
                value: s
            })),
            columnFilterModeOptions: ["arrIncludes"],
            enableFilterMatchHighlighting: false,
            enableGlobalFilter: false,
            enableColumnFilter: true,
            enableColumnFilterModes: false,
            enableColumnActions: false, enableSorting: false, enableHiding: false, enablePinning: false, enableResizing: false
        })
        columnDefs.push({
            id: "id",
            header: "ID",
            accessorKey: "id",
            size: 60,
            enableFilterMatchHighlighting: false,
            enableGlobalFilter: false,
            enableColumnFilter: false,
            enableColumnFilterModes: false,
            enableColumnActions: false, enableSorting: false, enableHiding: false, enablePinning: false, enableResizing: false,
            enableClickToCopy: true
        })
        columns.forEach((c) => {
            const filterVariants = incidentColFilters[c.column_type as TColumnTypes]?.filterVariant;
            const filterVariant = filterVariants ? filterVariants[columnFilterFns[DATA_ACCESSOR_PREFIX + c.key]] : "text";
            const colProps: MRT_ColumnDef<IIncident | IIncidentReport> = {
                id: DATA_ACCESSOR_PREFIX + c.key,
                header: c.title,
                accessorKey: DATA_ACCESSOR_PREFIX + c.key,
                Cell: ({renderedCellValue}) => {
                    return <span style={{textAlign: "left"}}>{
                        incidentColRenderers[c.column_type as TColumnTypes](renderedCellValue, props.emptyDataIndicators || [], {})
                    }</span>
                },
                size: getColDefaultWidth(c),
                enableResizing: true,
                enableColumnFilter: true,
                enableSorting: true,
                filterSelectOptions: ((c as IColumnSelect).options || []).map((o)=>{
                    return {
                        label: o,
                        value: o
                    }
                }),
                columnFilterModeOptions: incidentColFilters[c.column_type as TColumnTypes].columnFilterModeOptions,
                filterVariant: typeof filterVariant === "string" ? filterVariant : undefined,
                Filter: typeof filterVariant === "function" ? filterVariant : undefined,
            }
            columnDefs.push(colProps);
        })
        return columnDefs;
    },[columns, fetchData, props.emptyDataIndicators])

    const renderRowActions = (rowProps: { row: MRT_Row<IIncident | IIncidentReport> }) => {
        const row = rowProps.row;
        const goToIncidentCard = props.onClick;
        const isIncidentRow = "sources" in row.original;
        const rowAsIncident = (row.original as IIncident);
        const rowAsIncidentReport = (row.original as IIncidentReport);
        const parentRow = row.getParentRow()
        const parentIncident: IIncident | null = (parentRow?.original as IIncident | null)
        return isIncidentRow ?
            <Stack direction={"row"} alignItems={"center"} gap={1}>
                {
                    goToIncidentCard ?
                        <Tooltip
                            title={"לעיון ועריכה"}
                        >
                            <IconButton onClick={() => {
                                if (goToIncidentCard) {
                                    goToIncidentCard(rowAsIncident.id, {
                                        columnFilters,
                                        columnFilterFns,
                                        globalFilter,
                                        pagination,
                                        sorting,
                                        status: (columnFilters.filter(f => f.id === "approved")[0]?.value as incidentStatus[] | null)
                                    })
                                }
                            }}>
                                <EditIcon color={"primary"}/>
                            </IconButton>
                        </Tooltip> :
                        null
                }
                <Tooltip title={"מספר דיווחים על התקרית"}>
                    <Chip
                        label={""+rowAsIncident.sources.length}
                        variant={"filled"}
                        sx={{
                            '& .MuiChip-root': {
                                padding: '1px 1px', // Adjust these values to your preference
                            },
                        }}
                    />
                </Tooltip>
            </Stack> :
            <Stack direction={"row"}>
                <Tooltip title={
                    <Stack
                        direction={"row"} dir={"ltr"} gap={1}
                        style={{direction: "rtl", textAlign: "right"}}
                    >
                        <span>מקור:</span>
                        <span>
                                {
                                    [
                                        rowAsIncidentReport.website_title,
                                        rowAsIncidentReport.post_publication_date,
                                        rowAsIncidentReport.post_title
                                    ].filter(x => (x && x.length)).join(", ")
                                }
                            </span>
                    </Stack>
                }>
                    <a
                        href={rowAsIncidentReport.post_url}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        <IconButton>
                            <LinkIcon color={"primary"}/>
                        </IconButton>
                    </a>
                </Tooltip>
                {
                    (parentIncident?.sources?.length || 0) > 1 ?
                        <Tooltip title={"פיצול (הדיווח עוסק בתקרית אחרת)"}>
                            <IconButton onClick={async () => {
                                if(parentIncident?.id !== undefined) {
                                    await splitIncidentReport(parentIncident?.id, rowAsIncidentReport.id);
                                }
                            }}>
                                <CallSplitIcon/>
                            </IconButton>
                        </Tooltip> : null
                }
            </Stack>
    }

    const splitIncidentReport = async(incidentId: number, incidentReportId: number) => {
        const splitRes = await splitIncidentReportWithId(incidentReportId);
        if(splitRes && !splitRes.error){
            const splitNotification: ISplitNotification = {
                actionType: INCIDENT_ACTIONS.SPLIT,
                incidentIds: [incidentId],
                notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
                splitFrom: incidentId,
                splitInto: [incidentId, splitRes["split_into"]],
                splitReports: [incidentReportId]
            }
            props.notificationContext.addNotification(splitNotification);
        await fetchData();
        }
    }

    const mergeSelectedIncidents = async() => {
        const selectedIds = table.getSelectedRowModel().rows.map(r => {
            return r.original.id
        })
        props.permanentlySelectedRows?.forEach(id=>{
            if(!selectedIds.includes(id)){
                selectedIds.push(id)
            }
        })
        const mergeInto = props.permanentlySelectedRows && props.permanentlySelectedRows.length ?
            props.permanentlySelectedRows[0] : null
        await mergeIncidents(
            selectedIds, mergeInto,
            props.notificationContext,
            (merged_into, merged) => {
                if (props.onMerge) {
                    props.onMerge(merged_into, merged)
                }
            },
            () => {
            }
        );
        await fetchData();
    }

    const renderTopToolbarCustomActions = (tableProps: {table: MRT_TableInstance<IIncident | IIncidentReport>}) => {
        const table = tableProps.table;
        const selectedIds = table.getSelectedRowModel().rows.map(r => {
            return r.original.id
        })
        props.permanentlySelectedRows?.forEach(id=>{
            if(!selectedIds.includes(id)){
                selectedIds.push(id)
            }
        })
        return <Stack direction={"row"} gap={2}>
            <Button
                color="primary"
                disabled={!(selectedIds.length > 1)}
                onClick={async () => {
                    await mergeSelectedIncidents();
                }}
                variant="contained"
                startIcon={<MergeIcon/>}
            >
                Merge Selected Incidents
            </Button>
            <FormControl>
                <InputLabel>Switch Status To</InputLabel>
                <Select
                    disabled={!(selectedIds.length > 0)}
                    label={"Switch Status To"}
                    sx={{width: "200px"}}
                    value={""}
                    onChange={async (e)=>{
                        const selectedStatus = e.target.value as incidentStatus;
                        const selectedIds = table.getSelectedRowModel().rows.map(r => {
                            return r.original as IIncident
                        })
                        await changeIncidentsApprovalStatus(selectedIds, selectedStatus);
                    }}
                >
                    <MenuItem value={""}/>
                    {
                        incidentStatusOptions.map((s) => {
                            return <MenuItem key={s} value={s}>{s}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Stack>
    }


    const tableOptions:MRT_TableOptions<IIncident|IIncidentReport> = {
        columns: tableColumns,
        data: data,

        enableStickyHeader: true,
        muiTableHeadCellProps: {
            sx: {
                '& .Mui-TableHeadCell-Content-Wrapper': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }
            },
        },


        //rowPinningDisplayMode: 'select-sticky',
        //enableRowPinning: true,

        getSubRows: (row) => {
            return "sources" in row ? row.sources :  undefined
        },
        enableExpandAll: true, //hide expand all double arrow in column header
        enableExpanding: true,
        filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
        columnFilterDisplayMode: 'popover',
        enableColumnFilterModes: true,
        enableMultiSort: true,
        enableFullScreenToggle: false,
        paginateExpandedRows: true,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                enableResizing: false, //allow resizing
                size: 30, //make the expand column wide
            },
            'mrt-row-actions': {
                header: 'Actions', //change header text
                size: 90, //make actions column wider
                grow: false
            },
        },
        muiExpandButtonProps: {
            title: "Expand/Collapse Incident Reports"
        },

        enableColumnDragging: true,
        enableColumnOrdering: true,
        muiColumnDragHandleProps: {
            title: "Move Column"
        },

        enableColumnResizing: true,
        enableColumnPinning: true,
        layoutMode:'grid-no-grow',
        columnResizeDirection: "ltr",

        onColumnOrderChange: setColumnOrder,
        onColumnPinningChange: setColumnPinning,
        onColumnSizingChange: setColumnSizing,
        onColumnVisibilityChange: setColumnVisibility,

        enableRowActions: true,
        renderRowActions: renderRowActions,


        enableRowSelection: (row) => { return ("sources" in row.original) },
        enableSubRowSelection: false,
        positionToolbarAlertBanner: "none",
        getRowId: (row)=>("id_"+row.id),
        muiSelectCheckboxProps: {title: "toggle incident selection"},

        onRowSelectionChange: (updater) => {
            setRowSelection((prev) => {
                const newRowSelection = typeof updater === 'function' ? updater(prev) : updater;
                // Ensure the specific rows stay selected
                const updatedSelection = { ...newRowSelection };
                props.permanentlySelectedRows?.forEach(id => {
                    updatedSelection["id_"+id] = true;
                });
                return updatedSelection;
            });
        },

        //add custom action buttons to top-left of top toolbar
        renderTopToolbarCustomActions: renderTopToolbarCustomActions,

        onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        onColumnFilterFnsChange: setColumnFilterFns,
        onSortingChange: setSorting,
        autoResetPageIndex: false,
        initialState: {
            density: "compact",
            showGlobalFilter: true,
        },
        state: {
            columnFilters,
            columnFilterFns,
            globalFilter,
            isLoading,
            pagination,
            sorting,
            rowSelection,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            columnOrder,
            columnPinning,
            columnVisibility,
            columnSizing
        }, //pass the pagination state to the table
        rowCount: rowCount,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        muiPaginationProps:{
            rowsPerPageOptions: [10, 20, 50],
            showFirstButton: false,
            showLastButton: false,
        },
        muiTableContainerProps: {
            sx:{height: "65vh"}
        }
    }


    const table = useMaterialReactTable(tableOptions);

    return <React.Fragment>
        <MaterialReactTable table={table}/>
    </React.Fragment>
}

export default withNotification<Props>(IncidentTable);
