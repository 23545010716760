import React from 'react'
import server from "../../services/server";
import {
    Button,
    CircularProgress, Divider, FormControlLabel,
    Snackbar,
    Stack,
    Switch,
    TextField,
} from "@mui/material";
import WebsitePreviewer, {ISourceSelectors} from "./WebsitePreviewer";

interface IProps {
    id: number
}

interface ISourceConfig extends ISourceSelectors{
    title: string | null,
    url: string | null,
    load_in_browser?: boolean,
    date_added?: string,
}
interface IState {
    loadingData: boolean,
    loadingError: string | null,
    data: null | ({ [key: string]: any } & ISourceConfig),
    awaitingSave: boolean,
    saveNotification: string | null,
    loadingFrontPagePreview: boolean,
    frontPageHTML: string | null
}

type DataFieldType = "bool" | "str" | "number"

interface IDataField {
    title: string,
    key: string,
    type: DataFieldType,
    disabled?: boolean
}

const DataFieldRenderer: {[key in DataFieldType]: (f: IDataField, value: any, onChange:(value: any)=>any)=>JSX.Element} = {
    "str": (f, value, onChange)=><TextField
        disabled={!!f.disabled}
        label={f.title}
        key={f.key}
        value={value || ""}
        variant="outlined"
        placeholder={f.title}
        onChange={(e)=>{
            onChange(e.target.value);
        }}
    />,
    "number": (f, value, onChange)=><TextField
        disabled={!!f.disabled}
        label={f.title}
        key={f.key}
        value={value || ""}
        variant="outlined"
        placeholder={f.title}
        onChange={(e)=>{
            onChange(e.target.value);
        }}
    />,
    "bool": (f, value, onChange)=><FormControlLabel key={f.key} control={
        <Switch
            disabled={!!f.disabled}
            checked={!!value}
            onChange={(e)=>{
                onChange(e.target.checked);
            }}
        />
    } label={f.title}/>
}

class SourceManager extends React.Component<IProps, IState> {
    private endPoint = "sources";
    private fields: IDataField[] = [
        {title: "Source Name", key: "title", type: "str"},
        {title: "URL", key: "url", type: "str"},
        {title: "Enabled", key: "is_active", type: "bool"},
        {title: "Requires JS", key: "load_in_browser", type: "bool"},
        {title: "Article Entry Selector (optional)", key: "article_entry_selector", type: "str"},
        {title: "Title Selector (optional)", key: "title_selector", type: "str"},
        {title: "Date Selector (optional)", key: "date_selector", type: "str"},
        {title: "Body Selector (optional)", key: "body_selector", type: "str"},
        {title: "Date Added", key: "date_added", type: "str", disabled: true},
    ];

    constructor(props: IProps) {
        super(props);
        this.state = {
            loadingData: false,
            loadingError: null,
            data: null,
            awaitingSave: false,
            saveNotification: null,
            loadingFrontPagePreview: false,
            frontPageHTML: null
        }
    }

    async componentDidMount(){
        await this.fetchData();
    }

    async componentDidUpdate(prevProps:Readonly<IProps>, prevState:Readonly<IState>, snapshot?:any){
        const prevId = prevProps.id;
        const currId = this.props.id;
        if(prevId !== currId){
            this.setState((curr)=>({...curr, loadingFrontPagePreview: false, frontPageHTML: null}), async ()=>{
                await this.fetchData()
            })
        }
    }

    private async fetchData() {
        if(this.state.loadingData){return}
        const currId = this.props.id;
        this.setState((curr)=>({...curr, loadingData: true}),async ()=>{
            const res = await server.get(this.endPoint + "/" + currId);
            if (res && !res.error) {
                this.setState((curr)=>({
                    ...curr, data: res,
                    loadingData: false, loadingError: null
                }))
            }
            else {
                this.setState((curr)=>({
                    ...curr, loadingData: false,
                    loadingError: res?.error || "error - failed to load data"
                }))
            }
        })
    }

    private async previewSource() {
        const source = this.state.data;
        if(this.state.loadingFrontPagePreview || !source){return}
        this.setState((curr)=>({...curr, loadingFrontPagePreview: true}),async ()=>{
            const res = await server.post(this.endPoint + "/preview/", {
                url: source.url,
                load_in_browser: source.load_in_browser,
            });
            if (res && !res.error) {
                this.setState((curr)=>({
                    ...curr, frontPageHTML: res,
                    loadingData: false, loadingError: null
                }))
            }
            else {
                this.setState((curr)=>({
                    ...curr, loadingData: false,
                    loadingError: res?.error || "error - failed to load data"
                }))
            }
        })
    }

    private editData(field: string, value: any){
        const data = this.state.data;
        if(!data){return}
        data[field] = value;
        this.setState((curr)=>({...curr, data}))
    }

    private async saveData(){
        if(this.state.awaitingSave || !this.state.data){return}
        this.setState((curr)=>({...curr, awaitingSave: true}), async ()=>{
            const data = this.state.data;
            const id = data?.id;
            if(id === undefined){
                this.setState((curr)=>({
                    ...curr, awaitingSave: false,
                    saveNotification: res?.error || "error - couldn't save data"
                }))
            }
            const res = await server.post(this.endPoint + "/" + id + "/",
                {...data}
            );
            if (res.success) {
                this.setState((curr)=>({
                    ...curr,
                    awaitingSave: false, saveNotification: "data saved",
                }), ()=>{
                    this.fetchData();
                })
            }
            else {
                this.setState((curr)=>({
                    ...curr, awaitingSave: false,
                    saveNotification: res?.error || "error - couldn't save data"
                }))
            }
        });
    }

    private getSaveNotifications(){
        const msg = this.state.saveNotification;
        return <Snackbar
            open={msg !== null}
            autoHideDuration={3000}
            onClose={()=>{this.setState((curr)=>(
                {...curr, saveNotification: null, awaitingSave: false}
            ))}}
            message={msg}
        />
    }

    private getDataEditor():JSX.Element {
        const data = this.state.data;
        if(data === null){
            return <CircularProgress/>
        }
        return <Stack direction={"column"} gap={1} alignItems={"left"} dir={"ltr"}>
            {
                this.fields.map((f, j) => {
                    return DataFieldRenderer[f.type](
                        f,
                        data[f.key],
                        (value) => {
                            this.editData(f.key, value);
                        }
                    )
                })
            }
        </Stack>
    }

    render() {
        const data = this.state.data;
        return <div>{
                    this.state.loadingData ? <CircularProgress/> : (
                        this.state.loadingError ? <span className={"data-load-error"}>
                            {this.state.loadingError}
                        </span> : <Stack
                            direction={"column"}
                            gap={1} divider={<Divider orientation="horizontal" flexItem />}
                        >
                            {this.getDataEditor()}
                            {
                                data?.url ? <WebsitePreviewer
                                    url={data?.url}
                                    load_in_browser={!!data?.load_in_browser}
                                    selectors={(data as ISourceSelectors)}
                                    setSelectors={(s: ISourceSelectors)=>{
                                        const sourceData = this.state.data;
                                        if(sourceData) {
                                            const newSourceData = {...sourceData, ...s};
                                            this.setState((curr)=>({...curr, data: newSourceData}))
                                        }
                                    }}
                                /> : null
                            }
                            <div className={"save-section-wrap"}>
                            {
                                this.state.awaitingSave ?
                                    <CircularProgress/> :
                                    <Button
                                        onClick={async ()=>{
                                            await this.saveData();
                                        }}
                                        variant={"outlined"}
                                    >
                                        Save
                                    </Button>
                            }
                            </div>
                        </Stack>
                    )
                }
                {this.getSaveNotifications()}
        </div>
    }
}

export default SourceManager;