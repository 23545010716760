import React from 'react'
import {
    IColumn, IColumnArray,
    IColumnCity,
    IColumnDate,
    IColumnNumber, IColumnObject,
    IColumnSelect,
    IColumnText, ColumnTypes
} from "../../../services/types/columns";
import BaseFieldDefinition, {IIncidentFieldProps} from "./BaseFieldDefinition";
import FieldDefinitionText from "./FieldDefinitionText";
import FieldDefinitionCity from "./FieldDefinitionCity";
import FieldDefinitionNumber from "./FieldDefinitionNumber";
import FieldDefinitionSelect from "./FieldDefinitionSelect";
import FieldDefinitionDate from "./FieldDefinitionDate";
import FieldDefinitionObject from "./FieldDefinitionObject";
import FieldDefinitionArray from "./FieldDefinitionArray";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl, FormControlLabel, Grid, Input,
    MenuItem,
    Select, Slider,
    Stack,
    TextField, Tooltip
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default class FieldDefinition extends BaseFieldDefinition<IColumn> {
    constructor(props:IIncidentFieldProps<IColumn>) {
        super(props);
    }

    getGenericFieldManager = ():JSX.Element => {
        const columnType = this.props.column.column_type;
        switch (columnType){
            case "TEXT":
                return <FieldDefinitionText {...this.props} column={(this.props.column as IColumnText)}/>
            case "NUMBER":
                return <FieldDefinitionNumber {...this.props} column={(this.props.column as IColumnNumber)}/>
            case "CITY":
                return <FieldDefinitionCity {...this.props} column={(this.props.column as IColumnCity)}/>
            case "SELECT":
                return <FieldDefinitionSelect {...this.props} column={(this.props.column as IColumnSelect)}/>
            case "DATE":
                return <FieldDefinitionDate {...this.props} column={(this.props.column as IColumnDate)}/>
            case "OBJECT":
                return <FieldDefinitionObject {...this.props} column={(this.props.column as IColumnObject)}/>
            case "ARRAY":
                return <FieldDefinitionArray {...this.props} column={(this.props.column as IColumnArray)}/>
        }
        return <div>missing renderer</div>
    }

    private switchColumnType(newType: string):IColumn {
        const column = this.props.column;
        const columnType = column.column_type;
        if(newType === columnType){return column}
        column.column_type = newType;
        switch (newType){
            case "TEXT":
                const columnAsText:IColumnText = { multi_line: false, translation: "", ...column}
                return columnAsText
            case "NUMBER":
                const columnAsNumber:IColumnNumber = {...column}
                return columnAsNumber
            case "CITY":
                const columnAsCity:IColumnCity = {...column, translation: "English"}
                return columnAsCity
            case "SELECT":
                const columnAsSelect:IColumnSelect = {...column, options: ["option 1", "option 2", "option 3"]}
                return columnAsSelect
            case "DATE":
                const columnAsDate:IColumnDate = {...column}
                return columnAsDate
            case "OBJECT":
                const columnAsObject:IColumnObject = {...column, properties: []}
                return columnAsObject
            case "ARRAY":
                const columnAsArray:IColumnArray = {...column, entries: {
                        column_type: "TEXT",
                        description: "",
                        key: "",
                        multi_line: false,
                        title: "",
                        translation: ""
                    }
                }
                return columnAsArray
        }
        return column;
    }

    render() {
        const column = this.props.column;
        const onEdit = this.props.onEdit;
        return <Accordion
            expanded={this.state.expanded}
            onChange={(e, expanded)=>{
                this.setState((curr)=>({...curr, expanded: expanded}))
            }}
        >
                <AccordionSummary>{column.title}</AccordionSummary>
                <AccordionDetails>
                    { this.state.expanded ?
            <Stack direction={"column"} gap={2}>
                <FormControl className={"fill-width-util"}>
                    <TextField
                        label={"Title"}
                        className={"fill-width-util"}
                        variant="outlined"
                        value={column.title}
                        InputProps={{
                            readOnly: !onEdit,
                        }}
                        onChange={(e)=>{
                            if(onEdit){
                                column.title = e.target.value
                                onEdit(column);
                            }
                        }}
                    />
                </FormControl>
                {this.props.depth !== 0 && !this.props.isArrayEntry ? <FormControl className={"fill-width-util"}>
                    <TextField
                        label={"Key (English Only)"}
                        className={"fill-width-util"}
                        variant="outlined"
                        value={column.key}
                        InputProps={{
                            readOnly: !onEdit,
                        }}
                        onChange={(e) => {
                            if (onEdit) {
                                let key = e.target.value;
                                key = key.toLowerCase();
                                key = key.replace(/\s/g, "_");
                                column.key = key;
                                onEdit(column);
                            }
                        }}
                    />
                </FormControl> : null}
                {
                    this.props.column.column_type === "ARRAY" ? null :
                        <FormControl className={"fill-width-util"}>
                            <TextField
                                label={"Description (GPT Prompt)"}
                                className={"fill-width-util"}
                                variant="outlined"
                                value={column.description}
                                InputProps={{
                                    readOnly: !onEdit,
                                }}
                                multiline={true}
                                rows={3}
                                onChange={(e)=>{
                                    if(onEdit){
                                        column.description = e.target.value
                                        onEdit(column);
                                    }
                                }}
                            />
                        </FormControl>
                }
                <Select
                    labelId={column.key}
                    label="Field Type"
                    variant="outlined"
                    value={column.column_type}
                    className={"fill-width-util"}
                    readOnly={!onEdit}
                    onChange={(e) => {
                        if (onEdit) {
                            onEdit(this.switchColumnType(e.target.value))
                        }
                    }}
                >
                    {
                        ColumnTypes.map((o: string, i: number) => {
                            return <MenuItem value={o} key={"option_" + i}>{o}</MenuItem>
                        })}
                </Select>
                <div>
                    {this.getGenericFieldManager()}
                </div>
                {["ARRAY", "OBJECT"].includes(this.props.column.column_type) ?
                    null :
                    <FormControl>
                        <FormControlLabel
                            sx={{
                                alignItems: 'flex-start',
                                maxWidth: '500px',
                                '& .MuiFormControlLabel-label': {
                                    textAlign: 'start',
                                },
                            }}
                            labelPlacement={"top"}
                            control={
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs>
                                        <Slider
                                            aria-label="weight"
                                            defaultValue={0}
                                            valueLabelDisplay="auto"
                                            shiftStep={0.05}
                                            step={0.05}
                                            min={0}
                                            max={1}
                                            value={column.incident_identifier || 0}
                                            onChange={(e, v) => {
                                                if (onEdit) {
                                                    column.incident_identifier = Array.isArray(v) ? v[0] || 0 : v
                                                    onEdit(column);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Input
                                            value={column.incident_identifier || 0}
                                            size="small"
                                            onChange={(e) => {
                                                if (onEdit) {
                                                    column.incident_identifier = parseFloat(e.target.value)
                                                    onEdit(column);
                                                }
                                            }}
                                            inputProps={{
                                                step: 0.05,
                                                min: 0,
                                                max: 1,
                                                type: 'number',
                                                'aria-labelledby': 'input-slider',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            label={
                                <Stack direction={"row"} gap={1}>
                                    <span>Uniqueness</span>
                                    <Tooltip
                                        title="Use this field to identify duplicate reports about the same incident">
                                        <InfoIcon color={"primary"}/>
                                    </Tooltip>
                                </Stack>
                            }
                        />
                    </FormControl>
                }
            </Stack>:null}
            </AccordionDetails>
            </Accordion>
    }
}