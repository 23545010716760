import server from "./server";
import {incidentStatus} from "./types/incidentStatus";
import {IColumn, IColumnSelect} from "./types/columns";
import stringSimilarity from "string-similarity";
import {
    ErrorNotification,
    IMergeNotification,
    INCIDENT_ACTIONS,
    NOTIFICATION_TYPE, NotificationContextType
} from "../UIComponents/IncidentNotifications/NotificationsContext";

export const getDistinctReportsCount = (data: any[], x: IColumn, emptyChecker: (v:any)=>boolean) => {
    const fixedData = data.map(v=>{
        if(v==="True"){return true}
        if(v==="False"){return false}
        return v
    }).map(v=>{
        if((typeof v === 'string' || v instanceof String)){
            const clean_v = v.toLowerCase().trim()
            if(x.column_type === "SELECT"){
                const optionalValues = (<IColumnSelect>x).options.map(o=>emptyChecker(o) ? "unknown" : (o||"").toLowerCase())
                return stringSimilarity.findBestMatch(("" + (clean_v || "")), optionalValues).bestMatch.target.toLowerCase();
            }
            return clean_v;
        }
        return v
    });
    const nonEmptyData = fixedData.filter((d)=>!emptyChecker(d));
    const distinctData = new Set(nonEmptyData)
    const distinctCount = distinctData.size;
    return distinctCount || 1;
}

export const splitIncidentReportWithId = async (incidentReportId: number) => {
    return await server.post("incident_report/split/", {incident_report_id: incidentReportId});
}

export const mergeIncidents = async (
    incidentReportIds: number[],
    mergeInto: number | null,
    notificationContext: NotificationContextType,
    successCallback?: (mergedInto: number, merged: number[]) => any,
    errorCallback?: (error: string) => any
) => {
    const mergeRes = await server.post("incidents/merge/", {incident_ids: incidentReportIds, merge_into: mergeInto});
    if(!mergeRes.error) {
            const mergeNotification: IMergeNotification = {
                actionType: INCIDENT_ACTIONS.MERGE,
                incidentIds: mergeRes.merged,
                mergedIncidents: mergeRes.merged,
                mergedInto: mergeRes.merged_into,
                notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION
            };
            notificationContext.addNotification(mergeNotification)
            if(successCallback){
                successCallback(mergeRes.merged_into, mergeRes.merged)
            }
        }
        else{
            const errorNotification: ErrorNotification = {
                actionType: null,
                title: mergeRes.error,
                notificationType: NOTIFICATION_TYPE.ERROR
            };
            notificationContext.addNotification(errorNotification);
            if(errorCallback){
                errorCallback(mergeRes.error)
            }
        }
}

export const setIncidentApprovalStatus = async (status: { [key: number]: incidentStatus }) => {
    return await server.post(
        "incident_status/",
        {"status": status}
    );
}