import VerifiedIcon from "@mui/icons-material/Verified";
import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DeleteIcon from "@mui/icons-material/Delete";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";

export type incidentStatus = "not approved" | "approved" | "removed"
export const incidentStatusOptions: incidentStatus[] = ["not approved", "approved", "removed"];
export const incidentStatusProperties: { [key in incidentStatus]: {
    descriptor: string,
    title: string,
    icon: JSX.Element,
    details?: string,
    availableActionsFromTableView: {
        setTo: incidentStatus,
        title: string,
        icon: JSX.Element
    }[]
} } = {
    "not approved": {
        title: "טרם אושר",
        details: "תקרית שפרטיה טרם עברו אימות ואושרו סופית",
        descriptor: "",
        icon: <PendingActionsIcon color={"primary"}/>,
        availableActionsFromTableView: [
            {
                setTo: "removed",
                title: "סימון מקרה כלא רלוונטי",
                icon: <DeleteIcon color={"error"}/>
            }
        ]
    },
    "approved": {
        title: "נבדק ואושר",
        details: "תקרית שפרטיה נבדקו ואומתו",
        descriptor: "",
        icon: <VerifiedIcon color={"success"}/>,
        availableActionsFromTableView: [
            {
                setTo: "not approved",
                title: "ביטול אישור פרטי מקרה",
                icon: <UnpublishedIcon color={"action"}/>
            }
        ]
    },
    "removed": {
        title: "הוסר",
        details: "תקרית לא רלוונטית שהוסרה מהמערכת",
        descriptor: "",
        icon: <RemoveCircleIcon color={"error"}/>,
        availableActionsFromTableView: [
            {
                setTo: "not approved",
                title: "ביטול סימון כלא רלוונטי",
                icon: <RestoreFromTrashIcon color={"info"}/>
            }
        ]
    }
}