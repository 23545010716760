import React from 'react'
import {
    Button,
    Card,
    CircularProgress, Divider, FormControl, FormControlLabel, Grid, Input, Slider,
    Stack, Tooltip,
} from "@mui/material";
import server from "../../services/server";
import {IIncident} from "../../services/types/incident";
import {isEmpty} from "./dataTreatment";
import {IColumn} from "../../services/types/columns";
import IncidentInfoCard from "./IncidentInfoCard";
import AlignDir from "../../services/languages/AlignDir";
import {TipsAndUpdatesTwoTone} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {LoadingButton} from "@mui/lab";

interface IProps {
    incidentId: number,
    data: any,
    columns: IColumn,
    emptyDataIndicators: string[],
    onMerge: (incidentId: number)=>Promise<any>
}

interface IState {
    similar: IIncident[] | null,
    loadingSimilar: boolean,
    similarityRequirement: number,
    awaitingMerge: {[key: number]: boolean}
}

class MergeSuggestions extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            similar: null,
            loadingSimilar: false,
            similarityRequirement: 0.7,
            awaitingMerge: {}
        }
    }

    async componentDidMount() {
        await this.fetchSimilar()
    }

    async componentDidUpdate(prevProps: Readonly<IProps>) {
        if(this.props.incidentId !== prevProps.incidentId){
            this.setState((curr)=>({...curr, similar: null, awaitingMerge: {}}), async ()=>{
                await this.fetchSimilar()
            })
        }
    }

    private async fetchSimilar() {
        if(this.state.loadingSimilar){return}
        this.setState((curr) => ({...curr, loadingSimilar: true}), async () => {
            const res = await server.post(
                "incident/similar/",
                {
                    "id": this.props.incidentId,
                    "data": this.props.data,
                    "standard": this.state.similarityRequirement
                }
            );
            const similar = res.map((x:any) => {
                x.data = JSON.parse(x.data)
                return x;
            }).sort((a: any, b: any)=>{return b.total_score - a.total_score})
            this.setState((curr)=>({...curr, similar, loadingSimilar: false}))
        })
    }

    private async mergeSuggestion(id: number) {
        const ongoingMerges = this.state.awaitingMerge;
        if(ongoingMerges[id]){return}
        ongoingMerges[id] = true;
        this.setState((curr)=>({...curr, awaitingMerge: ongoingMerges}), async ()=>{
            await this.props.onMerge(id);
            const ongoingMerges = this.state.awaitingMerge;
            ongoingMerges[id] = false;
            this.setState((curr)=>({...curr, awaitingMerge: ongoingMerges}), async ()=>{
                await this.fetchSimilar()
            })
        })
    }

    render() {
        const suggestions = this.state.similar?.slice() || null;
        return <div>
            <Stack
                direction={"row"}
                gap={2}
                alignItems={"center"}
            >
                <TipsAndUpdatesTwoTone/>
                <div className="title-wrap">
                    הצעות למיזוג
                </div>
                <FormControl>
                    <FormControlLabel
                        sx={{
                            alignItems: 'flex-start',
                            '& .MuiFormControlLabel-label': {
                                textAlign: 'start',
                            },
                        }}
                        labelPlacement={"top"}
                        control={
                            <Input
                                value={this.state.similarityRequirement}
                                size="small"
                                onChange={(e) => {
                                    this.setState((curr) => ({
                                        ...curr, similarityRequirement: parseFloat(e.target.value)
                                    }), async () => {
                                        await this.fetchSimilar()
                                    })
                                }}
                                inputProps={{
                                    step: 0.05,
                                    min: 0.5,
                                    max: 1,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        }
                        label={
                            <Stack direction={"row"} gap={1}>
                                <span>Similarity</span>
                                <Tooltip
                                    title="Adjust this value to determine how similar an incident has to be to be included in the suggestions">
                                    <InfoIcon color={"primary"}/>
                                </Tooltip>
                            </Stack>
                        }
                    />
                </FormControl>
            </Stack>
            {
                this.state.loadingSimilar && !suggestions ?
                    <CircularProgress/> : null
            }
            { suggestions ?
                <Stack
                    direction={"row"}
                    gap={2}
                    justifyContent={"start"}
                    sx={{
                        width: "100%",
                        padding: "1em",
                        boxSizing: "border-box",
                        overflowX: "auto",
                    }}
                >
                    {
                        suggestions.map((x, i) => {
                            return <React.Fragment key={i}><AlignDir direction={"ltr"}>
                                <Card
                                    variant={"outlined"}
                                    sx={{
                                        width: "30vw",
                                        backgroundColor: "#007FFF22"
                                    }}
                                >
                                    <Stack
                                        direction={"column"}
                                    >
                                        <div style={{
                                            height: "30vh",
                                            overflowY: "auto",
                                            padding: "1em",
                                            boxSizing: "border-box",
                                        }}>
                                            <IncidentInfoCard
                                                column={this.props.columns}
                                                data={x.data}
                                                emptyChecker={(v: any) => isEmpty(v, this.props.emptyDataIndicators)}
                                            />
                                        </div>
                                        <LoadingButton
                                            variant={"contained"}
                                            sx={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                            }}
                                            onClick={async () => {
                                                await this.mergeSuggestion(x.id);
                                            }}
                                            loading={this.state.awaitingMerge[x.id]}
                                        >
                                            Merge
                                        </LoadingButton >
                                    </Stack>
                                </Card>
                            </AlignDir></React.Fragment>
                        })
                    }
                </Stack> : null
            }
        </div>
    }
}

export default MergeSuggestions;