import React from 'react'
import { FormControl, TextField } from "@mui/material";
import {IColumnCity} from "../../../services/types/columns";
import BaseFieldDefinition, {IIncidentFieldProps} from "./BaseFieldDefinition";

export default class FieldDefinitionCity extends BaseFieldDefinition<IColumnCity> {
    constructor(props:IIncidentFieldProps<IColumnCity>) {
        super(props);
    }

    render() {
        const column = this.props.column;
        const onEdit = this.props.onEdit;

        return <div>
            <FormControl className={"fill-width-util"}>
                <TextField
                    label={"Language (English)"}
                    className={"fill-width-util"}
                    variant="outlined"
                    value={column.translation}
                    InputProps={{
                        readOnly: !onEdit,
                    }}
                    onChange={(e)=>{
                        if(onEdit){
                            column.translation = e.target.value
                            onEdit(column);
                        }
                    }}
                />
            </FormControl>
        </div>
    }
}