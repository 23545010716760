import React from 'react'
import "./IncidentViewer.scss"
import Paper from '@mui/material/Paper';
import {
    Badge, Box, Button,
    CircularProgress, Divider,
    IconButton, Modal,
    Popover, Snackbar,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {substr} from "stylis";
import server from "../../services/server";
import {
    IColumn,
    IColumnArray,
    IColumnObject, IColumnText,
} from "../../services/types/columns";
import {IIncident, IIncidentReport} from "../../services/types/incident";
import {isEmpty} from "./dataTreatment";
import {expandSourcesHandler} from "./fields/BaseIncidentField";
import IncidentField from "./fields/IncidentField";
import {CallSplit, OpenInNew, Settings, WebAsset} from "@mui/icons-material";
import SourcePreview, {ISourcePreview} from "./SourcePreview";
import {
    mergeIncidents,
    setIncidentApprovalStatus,
    splitIncidentReportWithId
} from "../../services/incidentManager";
import {incidentStatus} from "../../services/types/incidentStatus";
import {ICurationSessionWorkspace} from "../../services/types/workspace";
import IncidentTable from "../incident_browser/IncidentTable";
import AlignDir from "../../services/languages/AlignDir";
import MergeSuggestions from "./MergeSuggestions";
import withNotifications, {WithNotificationProps} from "../../UIComponents/IncidentNotifications/withNotifications";
import {
    INCIDENT_ACTIONS, ISaveNotification, ISplitNotification,
    IStatusChangeNotification,
    NOTIFICATION_TYPE
} from "../../UIComponents/IncidentNotifications/NotificationsContext";

interface IViewerProps {
    workspaceConfig: ICurationSessionWorkspace,
    onWorkspaceChange: (config: ICurationSessionWorkspace)=>any,
    goToBrowse?: (config: ICurationSessionWorkspace)=>any,
}

type IProps = IViewerProps & WithNotificationProps

interface IState {
    incident: IIncident | null,
    columns: IColumnObject | null,
    expandReportDetails: boolean,
    emptyDataIndicators: string[] | null,
    loadingIncident: boolean,
    awaitingStatusChange: boolean,
    fieldSourcesPopoverAnchor: HTMLElement | null,
    sourcesPopoverAnchor: HTMLElement | null,
    fieldSourcesPopoverData: ISourcesPopoverData | null,
    mergePopupOpen: boolean,
    incidentError: null | string,
    sourcePreviews: ISourcePreview[],
    viewHistory: number[],
    historyPos: number,
    editingWorkspace: boolean,
    saveTimeout: NodeJS.Timeout | null,
    awaitingSave: boolean,
    scheduleReSave: boolean,
}

export interface ISourcesPopoverData {
    column: IColumn,
    data: IIncidentReport[],
    onSelect: (value: IIncidentReport) => void
}

const AUTO_SAVE_DELAY = 1000;

class IncidentViewer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const id: number | null = this.props.workspaceConfig.incidentId || null
        const history: number[] = this.props.workspaceConfig.excludeIds || []
        if(id !== null && !history.includes(id)){
            history.push(id)
        }
        const historyPos:number = id === null ? history.length : history.indexOf(id);
        this.state = {
            viewHistory: history,
            historyPos: historyPos,
            editingWorkspace: false,
            incident: null,
            columns: null,
            expandReportDetails: false,
            emptyDataIndicators: null,
            loadingIncident: false,
            awaitingStatusChange: false,
            sourcesPopoverAnchor: null,
            fieldSourcesPopoverAnchor: null,
            fieldSourcesPopoverData: null,
            mergePopupOpen: false,
            incidentError: null,
            sourcePreviews: [],
            saveTimeout: null,
            awaitingSave: false,
            scheduleReSave: false,
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchIncident(),
            this.fetchEmptyDataIndicators(),
            this.fetchColumns()
        ]);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>) {
        const currIncidentId = this.props.workspaceConfig.incidentId;
        if(
            prevProps.workspaceConfig.incidentId === undefined ||
            this.state.incident?.id === undefined ||
            !currIncidentId ||
            currIncidentId === this.state.incident?.id ||
            prevProps.workspaceConfig.incidentId === currIncidentId
        ) {
            return
        }
        const viewHistory = this.state.viewHistory.slice();
        if(!viewHistory.includes(currIncidentId)){
            viewHistory.push(currIncidentId)
        }
        const historyPos = viewHistory.indexOf(currIncidentId);
        this.setState((curr)=>({...curr, historyPos, viewHistory}), async ()=>{
            await this.fetchIncident()
        })
    }

    private async fetchColumns() {
        const res = await server.get("columns/");
        if (res && !res.error) {
            const columns = (((res as IColumnObject).properties[0] as IColumnArray).entries as IColumnObject);
            return await new Promise<void>((resolve) => {
                this.setState((current) => ({...current, columns}), () => {
                    resolve()
                })
            })
        }
    }

    private async fetchIncident() {
        if (this.state.loadingIncident) {
            return
        }
        const workspace = this.props.workspaceConfig;
        const viewHistory = this.state.viewHistory.slice();
        const historyPos = this.state.historyPos;
        let fetchNextIncident = false;
        if (historyPos < viewHistory.length) {
            workspace.incidentId = viewHistory[historyPos];
        } else {
            workspace.incidentId = undefined;
            fetchNextIncident = true;
        }

        return await new Promise<void>((resolve) => {
            this.setState((current) => ({...current, loadingIncident: true}), async () => {
                const res = await server.post("workspace/incident/" , workspace);
                if (res && !res.error) {
                    const incident = res;
                    try {
                        incident.title = incident.title || "incident #" + incident.id;
                        incident.data = JSON.parse(incident.data);
                        incident.sources = JSON.parse(incident.sources);
                        incident.edited_fields = JSON.parse(incident.edited_fields);
                        incident.sources = incident.sources.map((ir: any) => {
                            try {
                                ir.data = JSON.parse(ir.data)
                            } catch (e) {
                            }
                            return ir
                        })
                    } catch (e) {
                        return null;
                    }
                    if (fetchNextIncident) {
                        viewHistory.push(incident.id);
                    }
                    return resolve(await new Promise<void>((resolve) => {
                        this.setState((current) => ({
                            ...current,
                            incident,
                            incidentError: null,
                            loadingIncident: false,
                            viewHistory
                        }), () => {
                            workspace.excludeIds = viewHistory.slice();
                            workspace.incidentId = incident.id;
                            this.props.onWorkspaceChange(workspace);
                            resolve();
                        })
                    }))
                } else {
                    const incidentError = res && res.error ? res.error : "unknown error"
                    this.setState((current) => ({
                        ...current,
                        incidentError, loadingIncident: false, viewHistory
                    }))
                }
            })
        })
    }

    async fetchEmptyDataIndicators() {
        const res = await server.get("null-data-flags/");
        const emptyDataIndicators = res.map((x:{flag:string})=>x.flag)
        return await new Promise<void>((resolve) => {
            this.setState((current) => ({
                ...current,
                emptyDataIndicators
            }), () => {
                resolve()
            })
        })
    }

    private async setIncidentStatus(status: incidentStatus) {
        const incident = this.state.incident;
        if (incident === null || this.state.awaitingStatusChange || this.state.awaitingSave) {
            return;
        }
        this.setState((current) => ({...current, awaitingStatusChange: true}), async () => {
            const setTo :{[key: number]: incidentStatus} = {};
            const prevStatus :{[key: number]: incidentStatus} = {};
            setTo[incident.id] = status;
            prevStatus[incident.id] = incident.approved;
            await setIncidentApprovalStatus(setTo);
            this.setState((current) => ({
                ...current,
                awaitingStatusChange: false,
            }), async () => {
                const notification: IStatusChangeNotification = {
                    incidentIds: [incident.id],
                    prevStatus: prevStatus,
                    newStatus: setTo,
                    notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
                    actionType: INCIDENT_ACTIONS.STATUS_CHANGE,
                    title: "סטטוס התקרית שונה",
                    onUndo: async () => {
                        await this.fetchIncident()
                    }
                };
                this.props.notificationContext.addNotification(notification)
                await this.fetchIncident();
            });
        });
    }


    /*     Data Renderer      */

    expandColumnSource: expandSourcesHandler = (sourcesPopoverAnchor: HTMLElement, sourcesPopoverData: ISourcesPopoverData) => {
        this.setState((current) => ({
            ...current,
            fieldSourcesPopoverAnchor: sourcesPopoverAnchor,
            fieldSourcesPopoverData: sourcesPopoverData
        }))
    }


    private editData = (data: any, field: string | null) => {
        const incident = this.state.incident;
        if (incident) {
            incident.data = data;
            if(field){
                if(!incident.edited_fields){
                    incident.edited_fields = [];
                }
                if(!incident.edited_fields.includes(field)){
                    incident.edited_fields.push(field)
                }
            }
            this.setState((current) => ({...current, incident}), () => {
                this.scheduleSave();
            })
        }
    };

    private editTitle = (title: string) => {
        const incident = this.state.incident;
        if (incident) {
            incident.title = substr(title, 0, Math.max(title.length, 500));
            this.setState((current) => ({...current, incident}), () => {
                this.scheduleSave();
            })
        }
    };

    private scheduleSave() {
        if (this.state.saveTimeout) {
            clearTimeout(this.state.saveTimeout);
        }
        const timeout: NodeJS.Timeout = setTimeout(async () => {
            clearTimeout(timeout);
            await this.saveData();
            this.setState((curr) => ({...curr, saveTimeout: null}))
        }, AUTO_SAVE_DELAY);
        this.setState((curr) => ({...curr, saveTimeout: timeout}));
    }

    private async saveData() {
        if (this.state.awaitingSave) {
            this.setState((curr) => ({...curr, scheduleReSave: true}))
            return;
        }
        const incident = this.state.incident;
        if (!incident) {
            return;
        }
        this.setState((current) => ({...current, awaitingSave: true}), async () => {
            const res = await server.post(
                "incident/",
                {
                    "id": incident.id,
                    "data": incident.data,
                    "title": incident.title,
                    "edited_fields": incident.edited_fields
                }
            );
            const notification: ISaveNotification = {
                stateAfter: undefined, stateBefore: undefined,
                incidentIds: [incident.id],
                notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
                actionType: INCIDENT_ACTIONS.SAVE,
                title: "השינויים נשמרו"
            };
            this.props.notificationContext.addNotification(notification)
            this.setState((current) => ({
                ...current,
                awaitingSave: false,
            }), () => {
                if (this.state.scheduleReSave) {
                    this.setState((current) => ({...current, scheduleReSave: false}), () => {
                        this.saveData();
                    })
                }
            });
        });
    }

    getTitleAdornment() {
        const incident = this.state.incident;
        if (incident === null) {
            return <CircularProgress/>
        }
        const currStatus = incident.approved;
        return <Stack direction="row" spacing={1}>
            {!this.state.awaitingStatusChange ? <React.Fragment>
                <Tooltip title={
                    <Stack dir={"rtl"}>
                        <span>מקורות:</span>
                        {incident.sources?.map(s => {
                            return <span key={s.id}>
                                {(s.website_title) + ", " + (s.post_publication_date || "??/??/??")}
                            </span>
                        })}
                    </Stack>
                } arrow placement={"bottom"}>
                    <IconButton
                        aria-label="sources"
                        color={"primary"}
                        onClick={(e) => {
                            this.setState((curr) => ({...curr, sourcesPopoverAnchor: (e.target as HTMLElement)}))
                        }}
                    >
                        <Badge
                            badgeContent={incident.sources?.length}
                            color={"success"}
                        >
                            <NewspaperIcon/>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"מיזוג"} arrow placement={"bottom"}>
                    <IconButton
                        aria-label="merge"
                        onClick={() => {
                            this.setState((curr) => ({...curr, mergePopupOpen: true}))
                        }}
                    >
                        <CallMergeIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"לא רלוונטי"} arrow placement={"bottom"}>
                    <IconButton
                        aria-label="remove"
                        color={"error"}
                        disabled={this.state.awaitingSave}
                        onClick={async () => {
                            await this.setIncidentStatus(currStatus === "removed" ? "not approved" : "removed")
                        }}
                        sx={currStatus === "removed" ? {backgroundColor: "error.main", color: "#fff"} : {}}

                    >
                        <RemoveCircleIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"אישור"} arrow placement={"bottom"}>
                    <IconButton
                        aria-label="approve"
                        color={"success"}
                        disabled={this.state.awaitingSave}
                        onClick={async () => {
                            await this.setIncidentStatus(currStatus === "approved" ? "not approved" : "approved")
                        }}
                        sx={currStatus === "approved" ? {backgroundColor: "success.main", color: "#fff"} : {}}
                    >
                        <VerifiedIcon/>
                    </IconButton>
                </Tooltip>
            </React.Fragment> : <CircularProgress/>}
        </Stack>
    }

    openSourcePreview(
        source: IIncidentReport,
        defaultPos: { x: number, y: number },
    ) {
        const sourcePreviews    : ISourcePreview[] = this.state.sourcePreviews.slice();
        sourcePreviews.push({source, defaultPos});
        this.setState((current) => ({...current, sourcePreviews}));
    }


    closeSourcePreview(previewIndex: number) {
        const sourcePreviews = this.state.sourcePreviews.slice();
        sourcePreviews.splice(previewIndex, 1);
        this.setState((current) => ({...current, sourcePreviews}));
    }

    private goToPrevIncident = () => {
        const viewHistory = this.state.viewHistory;
        const historyPos = this.state.historyPos;
        if (!viewHistory.length || !historyPos) {
            return;
        }
        this.setState((current) => ({...current, historyPos: historyPos - 1}), async () => {
            await this.fetchIncident();
        })
    }

    private goToNextIncident = () => {
        const historyPos = this.state.historyPos;
        this.setState((current) => ({...current, historyPos: historyPos + 1}), async () => {
            await this.fetchIncident();
        })
    }

    private getPrevButton = () => {
        return <div className={"relative-nav-button-wrap"} dir={"rtl"}>
            <Tooltip title={"למקרה הקודם"} arrow placement={"bottom"}>
                <span className={"fixed-nav-button-wrap"}>
                <IconButton
                    aria-label="previous incident"
                    color={"primary"}
                    size={"large"}
                    onClick={() => {
                        this.goToPrevIncident();
                    }}
                    disabled={!this.state.historyPos}
                >
                    <ArrowCircleRightIcon/>
                </IconButton>
                </span>
            </Tooltip>
        </div>
    }


    private getNextButton = () => {
        // const workspaceConfig = this.props.workspaceConfig;
        const editing = this.state.editingWorkspace;
        return <div className={"relative-nav-button-wrap"} dir={"ltr"}>
            <span className={"fixed-nav-button-wrap"}>
                <Stack gap={1} direction={"row-reverse"} alignItems={"center"}>
                    <Tooltip title={ "למקרה הבא" ||
                            <Stack gap={1}>
                                <Stack textAlign={"center"} style={{fontWeight: "bold", marginTop:"0.5em"}}>
                                    {editing ? "סביבת עבודה" : "למקרה הבא"}
                                </Stack>
                                { !editing ?
                                    <Stack direction={"row"}
                                           alignItems={"top"}
                                           dir={"rtl"} style={{textAlign: "right"}}
                                           justifyContent={"space-between"}
                                    >
                                        <Stack direction={"column"} gap={1} style={{margin: "1em 0"}}>
                                        <span>
                                            להוסיף סיכום פרטי סביבת עבודה
                                        </span>
                                        </Stack>
                                        <Tooltip title={"הגדרות סביבת עבודה"} arrow placement={"bottom"}>
                                            <IconButton
                                                aria-label="workspace configuration"
                                                size={"small"}
                                                color={"default"}
                                                style={{height: "1em", width: "1em", margin: "0.75em"}}
                                                onClick={()=>{this.setState((curr)=>({...curr, editingWorkspace: true}))}}
                                            >
                                                <Settings/>
                                            </IconButton>
                                        </Tooltip>
                                    </Stack> :
                                    <Stack direction={"column"} gap={1} style={{margin: "1em 0"}} alignItems={"center"}>
                                        להוסיף מנגנון ניהול סביבת עבודה
                                        <IconButton
                                            aria-label="workspace configuration"
                                            size={"small"}
                                            color={"success"}
                                            sx={{
                                                color: "#55ff55",
                                                backgroundColor: "green"
                                            }}
                                            style={{height: "1em", width: "1em", margin: "0.75em"}}
                                            onClick={()=>{}}
                                        >
                                            <VerifiedIcon/>
                                        </IconButton>
                                    </Stack>
                                }
                            </Stack>
                    }
                             arrow placement={"bottom"}
                             onClose={()=>{this.setState((curr)=>({...curr, editingWorkspace: false}))}}
                             onMouseDown={(e)=>{e.stopPropagation(); return false}}
                             disableFocusListener
                             classes={{ popper: "tooltip-with-input" }}
                             componentsProps={{
                                 tooltip: {
                                     sx: {
                                         zIndex: 1000
                                     },
                                 },
                             }}
                    >
                        <IconButton
                            aria-label="next incident"
                            color={"primary"}
                            size={"large"}
                            onClick={() => {
                                this.goToNextIncident();
                            }}
                        >
                            <ArrowCircleLeftIcon/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </span>
        </div>
    }

    private getIncidentCard() {
        const columns = this.state.columns;
        const incident = this.state.incident;
        const emptyDataIndicators = this.state.emptyDataIndicators
        const incidentSources: IIncidentReport[] = incident?.sources?.slice() || [];
        const incidentError = this.state.incidentError;
        const status = incident?.approved;
        const cardColor =
            status === "approved" ? "#00ff0030" :
                status === "removed" ? "#ff000030" :
                    status === "not approved" ? "#00000030" :
                        "#00000030";
        return <Paper style={{width: "50vw"}} className={"incident-card-outer"}>{
            incidentError ?
                <div className={"incident-error"}>
                    {incidentError === "no additional incidents match the search criteria" ?
                        <Stack direction={"column"} justifyContent={"center"}>
                        <span>
                        That's it! there are no more incidents matching the specified search criteria in the system.
                            </span>
                        {
                            this.props.goToBrowse ?
                            <Button
                                variant={"contained"}
                                onClick={()=>{this.props.goToBrowse?.(this.props.workspaceConfig)}}
                            >Back to Table View</Button> : null
                        }
                    </Stack> : "error: " + incidentError}
                </div>
                : (
                    incident && emptyDataIndicators && columns ?
                        <React.Fragment>
                            <TextField
                                className={"incident-card-title"}
                                label="שם תקרית"
                                variant="filled"
                                value={incident.title}
                                onChange={(e) => {
                                    this.editTitle(e.target.value)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        this.getTitleAdornment()
                                    ),
                                }}
                                sx={{
                                    backgroundColor: cardColor,
                                    borderRadius: "5px 5px 0 0"
                                }}
                            />
                            <div className={"incident-card-inner"}>
                                <IncidentField
                                    column={columns}
                                    data={incident.data}
                                    citation={null}
                                    sources={incidentSources}
                                    onEdit={this.editData}
                                    onClick={null}
                                    onSourcesClick={this.expandColumnSource}
                                    emptyChecker={(v: any) => isEmpty(v, emptyDataIndicators)}
                                />
                            </div>
                        </React.Fragment> :
                        <div className={"incident-loader-wrap"}><CircularProgress/></div>
                )
        }</Paper>
    }

    async splitIncidentReport(incidentId: number, incidentReportId:number) {
        const res = await splitIncidentReportWithId(incidentReportId);
        if (res && !res.error) {
            const newIncidentId = res["split_into"];
            const splitNotification: ISplitNotification = {
                actionType: INCIDENT_ACTIONS.SPLIT,
                incidentIds: [incidentId],
                notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
                splitFrom: incidentId,
                splitInto: [incidentId, newIncidentId],
                splitReports: [incidentReportId]
            }
            this.props.notificationContext.addNotification(splitNotification);
            const viewHistory = this.state.viewHistory.slice();
            let historyPos = this.state.historyPos;
            viewHistory.splice(historyPos + 1, 0, newIncidentId);
            this.setState(curr => ({...curr, viewHistory, historyPos}), async () => {
                await this.fetchIncident();
            });
        }
    }

    getSourcePopoverFieldTitleOverride(incident:IIncident, s: IIncidentReport, sourcesCount: number) {
        return <Stack
            gap={1}
            direction={"row"}
            alignItems={"center"}
        >
            <span>{s.website_title}</span>
            <Tooltip
                title={"לעיון"}
                arrow
                placement={"bottom"}
            >
                <a href={s.post_url} target={"_blank"} rel={"noreferrer"}>
                    <IconButton
                        aria-label="go to"
                        color={"primary"}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <OpenInNew fontSize="small"/>
                    </IconButton>
                </a>
            </Tooltip>
            <Tooltip
                title={"פתיחה בשולחן העבודה"}
                arrow
                placement={"bottom"}
            >
                <IconButton
                    aria-label="open"
                    color={"primary"}
                    onClick={(e) => {
                        this.openSourcePreview(
                            s,
                            {
                                x: e.clientX,
                                y: e.clientY
                            }
                        )
                        this.setState((current) => ({
                            ...current,
                            fieldSourcesPopoverAnchor: null, fieldSourcesPopoverData: null
                        }));
                        e.stopPropagation();
                    }}
                >
                    <WebAsset fontSize="small"/>
                </IconButton>
            </Tooltip>
            {
                sourcesCount > 1 ?
                    <Tooltip
                        title={"פיצול מקור לתקרית נפרדת"}
                        arrow
                        placement={"bottom"}
                    >
                        <IconButton
                            aria-label="open"
                            color={"primary"}
                            onClick={async (e) => {
                                await this.splitIncidentReport(incident.id, s.id);
                                this.setState((current) => ({
                                    ...current,
                                    fieldSourcesPopoverAnchor: null,
                                    fieldSourcesPopoverData: null,
                                    sourcesPopoverAnchor: null,
                                }));
                                e.stopPropagation();
                            }}
                        >
                            <CallSplit fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                    : null
            }
        </Stack>
    }

    private getSourcesPopover() {
        const incident = this.state.incident;
        if (!incident) {
            return null;
        }
        const sourcesPopoverData = incident.sources;
        return <Popover
            open={!!this.state.sourcesPopoverAnchor}
            anchorEl={this.state.sourcesPopoverAnchor}
            onClose={() => {
                this.setState((current) => ({
                    ...current,
                    fieldSourcesPopoverAnchor: null,
                    fieldSourcesPopoverData: null,
                    sourcesPopoverAnchor: null,
                }))
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            style={{maxHeight: "50vh", width: "50vw"}}
        >
            <div className={"sources-pop-over"}>{
                sourcesPopoverData ?
                    <Stack gap={1}>
                        {sourcesPopoverData.map((s: IIncidentReport, i: number) => {
                            const metadataColumn: IColumnText = {
                                column_type: "TEXT",
                                description: "",
                                key: "source metadata",
                                multi_line: false,
                                title: s.website_title,
                                translation: ""
                            }
                            return <React.Fragment key={i}>
                                <IncidentField
                                    column={metadataColumn}
                                    data={(s.post_publication_date || "??/??/??") + ", " + (s.post_title)}
                                    citation={null}
                                    sources={null}
                                    onEdit={null}
                                    onClick={null}
                                    onSourcesClick={null}
                                    emptyChecker={(v: any) => isEmpty(v, this.state.emptyDataIndicators)}
                                    labelOverride={this.getSourcePopoverFieldTitleOverride(incident, s, sourcesPopoverData.length)}
                                />
                            </React.Fragment>
                        })}
                    </Stack>
                    : null
            }</div>
        </Popover>
    }

    private getFieldSourcesPopover() {
        const incident = this.state.incident;
        if(!incident){return null}
        const fieldSourcesPopoverData = this.state.fieldSourcesPopoverData;
        return <Popover
            open={!!this.state.fieldSourcesPopoverAnchor}
            anchorEl={this.state.fieldSourcesPopoverAnchor}
            onClose={() => {
                this.setState((current) => ({
                    ...current,
                    fieldSourcesPopoverAnchor: null,
                    fieldSourcesPopoverData: null,
                    sourcesPopoverAnchor: null,
                }))
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            style={{maxHeight: "50vh"}}
        >
            <div className={"sources-pop-over"}>{
                fieldSourcesPopoverData ?
                    <Stack gap={1}>
                        {fieldSourcesPopoverData.data.map((s, i: number) => {
                            const sColumn = fieldSourcesPopoverData?.column;
                            if (!sColumn) {
                                return null
                            }
                            return <React.Fragment key={i}>
                                <IncidentField
                                    column={sColumn}
                                    data={s.data}
                                    citation={null}
                                    sources={null}
                                    onEdit={null}
                                    onClick={(v) => {
                                        fieldSourcesPopoverData?.onSelect(v);
                                        this.setState((current) => ({
                                            ...current,
                                            fieldSourcesPopoverAnchor: null,
                                            fieldSourcesPopoverData: null,
                                            sourcesPopoverAnchor: null,
                                        }))
                                    }}
                                    onSourcesClick={null}
                                    emptyChecker={(v: any) => isEmpty(v, this.state.emptyDataIndicators)}
                                    labelOverride={this.getSourcePopoverFieldTitleOverride(incident, s, fieldSourcesPopoverData?.data.length || 0)}
                                />
                            </React.Fragment>
                        })}
                    </Stack>
                    : null
            }</div>
        </Popover>
    }

    private getSourcesWorkspace() {
        const sourcePreviews = this.state.sourcePreviews.slice();
        return <div className={"preview-workspace"}>
            {
                sourcePreviews.map((sp, i) => {
                    return <SourcePreview
                        key={sp.source.id}
                        source={sp.source}
                        defaultPos={sp.defaultPos}
                        onClose={() => {
                            this.closeSourcePreview(i)
                        }}
                    />
                })
            }
        </div>
    }

    getMergeModal = () => {
        const columns = this.state.columns;
        const emptyDataIndicators = this.state.emptyDataIndicators;
        const currIncidentId = this.state.incident?.id

        return <Modal
            open={this.state.mergePopupOpen}
            onClose={() => {
                this.setState((curr) => ({...curr, mergePopupOpen: false}));
            }}
        >
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "80vw",
                    height: "70vh",
                    overflow: "auto",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
                dir={"rtl"}
            >
                {columns !== null && emptyDataIndicators !== null && currIncidentId !== undefined ?
                    <AlignDir direction={"ltr"}>
                        <IncidentTable
                            columnSchema={columns}
                            emptyDataIndicators={emptyDataIndicators}
                            permanentlySelectedRows={[currIncidentId]}
                            onMerge={()=>{
                                this.setState((curr) => ({...curr, mergePopupOpen: false}), async ()=>{
                                    await this.fetchIncident()
                                });
                            }}
                        />
                    </AlignDir> : <CircularProgress/>}

            </Box>
        </Modal>
    }

    render() {
        return <div>
            <Stack
                direction={"column"}
                justifyContent={"center"}
                gap={2}
                divider={<Divider orientation="horizontal" flexItem />}
            >
                <Stack direction={"row"} justifyContent={"center"} gap={2}>
                    {this.getPrevButton()}
                    {this.getIncidentCard()}
                    {this.getNextButton()}
                </Stack>
                {this.getSuggestedMerges()}
            </Stack>
            {this.getSourcesPopover()}
            {this.getFieldSourcesPopover()}
            {this.getSourcesWorkspace()}
            {this.getMergeModal()}
        </div>
    }

    private getSuggestedMerges() {
        const columns = this.state.columns;
        const incident = this.state.incident;
        const currIncidentId = incident?.id || null;
        const emptyDataIndicators = this.state.emptyDataIndicators
        if(currIncidentId === null || !columns || ! emptyDataIndicators){
            return null
        }
        return <MergeSuggestions
            incidentId={currIncidentId}
            data={this.state.incident?.data}
            columns={columns}
            emptyDataIndicators={emptyDataIndicators}
            onMerge={
                async (incidentId: number) => {
                    await mergeIncidents(
                        [incidentId, currIncidentId], currIncidentId,
                        this.props.notificationContext,
                        async () => {
                            return await this.fetchIncident()
                        },
                        () => {
                        }
                    );
                }
            }
        />
    }
}

export default withNotifications<IProps>(IncidentViewer);