import React from 'react';
import server from '../services/server';
import cookie from 'js-cookie';
import './login/Login.scss';
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';
import withRouter, {IRouterProps} from "../services/withRouter"
import {Stack, TextField} from "@mui/material";
import {encodeBrowseWorkspaceSearchParams, getDefaultBrowseWorkspace} from "../services/types/workspace";

library.add(faSignInAlt);

interface IProps extends IRouterProps {
}

interface IState {
    redirect?: string,
    email: string,
    code: string,
    codeSent: boolean,
}

export default (withRouter(class Login extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            redirect: "",
            email: props.searchParams.get("email") || "",
            code: props.searchParams.get("code") || "",
            codeSent: false,
        };
        if (this.state.code.length && this.state.email.length) {
            this.verifyLoginCode()
        }
    }

    componentDidMount() {
        const token = cookie.get('token')
        if (token && token.length) {
            server.get("permissions/", {ignoreErrors: true}).then((res) => {
                if (res.valid) {
                    const unapprovedFilter:{[key:string]: any} = {"s": encodeBrowseWorkspaceSearchParams(
                        getDefaultBrowseWorkspace(["not approved"])
                        )
                    };
                    this.props.navigate({
                        pathname: "/incidents/",
                        search: Object.keys(unapprovedFilter).map(k=>""+k+"="+unapprovedFilter[k]).join("&")
                    });
                }
            })
        }
    }

    validateEmail = (email: string) => {
        //checks for example@example.example, such that [example] doesn't contain a '@'
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };


    identifyByEmail = () => {
        const email = this.state.email;
        if (this.state.codeSent) {
            return
        }
        server.post('login/identify/', {'email': email})
            .then(() => {
                this.setState((curr) => ({...curr, codeSent: true, email: email}));
            });
    }

    setLoginCode = (code: string) => {
        this.setState((curr) => ({...curr, code}))
    }

    backToEmailInput = () => {
        this.setState((curr) => ({...curr, codeSent: false}), () => {
        });
    };

    verifyLoginCode() {
        const data = {'email': this.state.email, 'code': this.state.code};
        server.post("login/login/", data)
            .then(res => {
                if (res["token"]) {
                    cookie.set('token', res["token"], {expires: 30});
                    if (this.state.redirect) {
                        try {
                            this.props.navigate(decodeURIComponent(this.state.redirect));
                            return;
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    const unapprovedFilter:{[key:string]: any}  = {"s": encodeBrowseWorkspaceSearchParams(
                        getDefaultBrowseWorkspace(["not approved"])
                        )
                    };
                    this.props.navigate({
                        pathname: "/incidents/",
                        search: Object.keys(unapprovedFilter).map(k=>""+k+"="+unapprovedFilter[k]).join("&")
                    });
                }
            });
    }

    render() {
        /**Stage 1 - Verification Method Selection**/
            //TODO - the arabic here isn't up to date
        const identification =
                <Stack dir={"column"} alignItems={"center"} gap={2}>
                    <div className='identification-input-title'>
                        <div>
                            הזינו כתובת אימייל
                        </div>
                    </div>
                    <TextField
                        dir="ltr"
                        variant={"filled"}
                        placeholder="Email Address"
                        onChange={(e) => {
                            this.setState((curr) => ({...curr, email: e.target.value}))
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                this.identifyByEmail();
                            }
                        }}
                        sx={{
                            backgroundColor: "#ffffff", margin: "0 auto", borderRadius: "3px 3px 0px 0px"
                        }}
                    />
                </Stack>;
        /**Stage 2 - Login Code Input**/
        const loginCode =
            <div>
                <br/>
                <div className='code-input-title'>
                    {"הזינו את הקוד שנשלח אליכם במייל"}
                </div>
                <br/>
                <input
                    className={"login-code"}
                    value={this.state.code}
                    onChange={(e) => {
                        this.setLoginCode(e.target.value)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            this.verifyLoginCode();
                        }
                    }}
                />
                <button
                    type={"button"}
                    className={"submit-code-button"}
                    onClick={() => {
                        this.verifyLoginCode()
                    }}
                >
                    <FontAwesomeIcon icon="sign-in-alt"/>
                </button>
                <div className={"back-to-identification"} onClick={() => {
                    this.backToEmailInput()
                }}>חזרה
                </div>
            </div>;
        return (
            <div className='page-wrap-login' dir="rtl">
                {
                    <div>
                        {this.state.codeSent ? loginCode : identification}
                    </div>
                }
            </div>
        )
    }
}))

