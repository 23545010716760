import React, {createRef} from 'react'
import { getCssSelector } from "css-selector-generator";


interface IProps {
    src_html: string,
    selector?: string,
    selectableTags?: string[],
    setSelector?: (newSelector?: string)=>any,
    singleSelect: boolean
}

interface IState {
    wrapped_html: string,
    doc_html: Document
}


class HTMLSelectorGenerator extends React.Component<IProps, IState> {
    private iframeRef: React.RefObject<HTMLIFrameElement>;
    constructor(props: IProps) {
        super(props);
        const parser = new DOMParser();
        const doc_html = parser.parseFromString(this.props.src_html, 'text/html');
        this.state = {
            wrapped_html: this.wrap_html(props.src_html),
            doc_html
        }
        this.iframeRef = createRef();
    }
    componentDidMount() {
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    postSelector() {
        // Send the combined selector back to the iframe
        const iframe = this.iframeRef.current;
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage({
            type: 'UPDATE_STYLES',
            selector: this.props.selector
          }, '*');
        }
    }

    handleMessage = (event: any) => {
        if (event.data.type === 'UPDATE_SELECTOR') {
            let newSelector = event.data.selector;
            if(!event.data.ctrl){
                const doc_html = this.state.doc_html;
                const selected_elements = this.props.singleSelect ?
                    doc_html.querySelector(newSelector) :
                    doc_html.querySelectorAll(newSelector);
                const shortSelector = getCssSelector(selected_elements, {selectors: ['id', 'class', 'tag', 'nthchild']});
                if(shortSelector){
                    newSelector = shortSelector;
                }
            }
            if(!this.props.singleSelect && event.data.shift && this.props.selector && this.props.selector.length) {
                let selectors = this.props.selector.split(", ")
                if(selectors.includes(newSelector)){
                    selectors = selectors.filter(x=>x!==newSelector);
                }
                else{
                    selectors.push(newSelector);
                }
                newSelector += selectors.join(", ");
            }
            if(this.props.setSelector){
                this.props.setSelector(newSelector)
            }
        }
    };

    async componentDidUpdate(prevProps:Readonly<IProps>, prevState:Readonly<IState>, snapshot?:any){
        if(this.props.src_html !== prevProps.src_html){
            const parser = new DOMParser();
            const doc_html = parser.parseFromString(this.props.src_html, 'text/html');
            this.setState((curr)=>({
                ...curr,
                wrapped_html: this.wrap_html(this.props.src_html),
                doc_html
            }))
        }
        if(this.props.selector !== prevProps.selector){
            this.postSelector();
        }
    }

    remove_script_wrap = (str: string) => {
        return str.split("<script>")[1].split("</script>")[0]
    }

    wrap_html = (src_html: string) => {
        const selectableTags = this.props.selectableTags;
        const parser = new DOMParser();
        const doc = parser.parseFromString(src_html, 'text/html');

        const scriptTags = doc.querySelectorAll('script');
        scriptTags.forEach((script) => script.remove());
        const styleTags = doc.querySelectorAll('style');
        styleTags.forEach((style) => style.remove());

        // Remove all elements with display: none or visibility: hidden in inline styles
        const elementsWithInlineStyles = doc.querySelectorAll('[style]');
        elementsWithInlineStyles.forEach((element) => {
            let style = element.getAttribute('style') || "";
            style = style.replace(/ /g,'')
            if (style.includes('display:none') || style.includes('visibility:hidden')) {
                element.removeAttribute('style');
            }
        });

        const divElements = doc.querySelectorAll('div');
        divElements.forEach((div) => {
            // Check if the div is empty (no child nodes and no text content)
            if (!div.hasChildNodes() && !div.innerText.trim()) {
                div.style.display = "none";
            }
        });

        const imgElements = doc.querySelectorAll('img');
        imgElements.forEach((img) => {
            img.style.display = "none";
        });

        const headerFooterElements:NodeListOf<HTMLElement> = doc.querySelectorAll('header, footer');
        headerFooterElements.forEach((element) => {
            element.style.display = "none";
        });

        const interceptLinkClicks = doc.createElement('script');
        interceptLinkClicks.textContent = this.remove_script_wrap(`<script>
            const links = Array.from(document.querySelectorAll('a'));
        
            for(const a of links) {
                a.addEventListener('click', function(e) {
                    e.preventDefault();
                });
            }
            
            // Function to calculate a generalized CSS selector
            function getGeneralizedSelector(el) {
              let selector = '';
        
              // Traverse up the DOM tree to find common attributes
              // can't use ampersand because the XML serializer attempts to escape it
              while (!(!el || !el.tagName || el.tagName.toLowerCase() === 'body')) {
                let part = el.tagName.toLowerCase();
                if (el.className) {
                  // Include all classes in the selector part
                  part += '.' + Array.from(el.classList).join('.');
                } else if (el.id) {
                  // If an ID is present, include it (but be careful: IDs should be unique)
                  part += '#' + el.id;
                }
        
                selector = part + (selector ? ' > ' + selector : '');
                el = el.parentElement;
              }
        
              // Generalize the selector by removing specific :nth-of-type() selectors or overly specific attributes
              return selector;
            }
            
            function setSelector(selectors){
                // Create a style element if it doesn't exist
                let styleEl = document.getElementById('dynamic-styles');
                if (styleEl) {
                  styleEl.parentNode.removeChild(styleEl);
                }
                styleEl = document.createElement('style');
                styleEl.id = 'dynamic-styles';
                document.head.appendChild(styleEl);
                
                if(!selectors || !selectors.length){return}
                
                // Add a CSS rule to apply the green overlay
                selectors.split(", ").forEach(s=>{
                    styleEl.sheet.insertRule(
                      s + ' { position: relative; z-index: 1; }',
                      styleEl.sheet.cssRules.length
                    );
                    styleEl.sheet.insertRule(
                      s + '::after { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 255, 0, 0.3); pointer-events: none; z-index: 2; }',
                      styleEl.sheet.cssRules.length
                    );
                })
            }
            
            document.addEventListener('click', function(event) {
            // Prevent the default action to avoid triggering links, buttons, etc.
                event.preventDefault();
                
                if (${selectableTags ? selectableTags.map(t=> `event.target.tagName.toLowerCase() !== '${t.toLowerCase()}'`).join('||') : 'false'}) {
                    return;
                }
        
                // Calculate the generalized selector for the clicked element
                const selector = getGeneralizedSelector(event.target);
                console.log('Generalized Selector:', selector);
                
                window.parent.postMessage({
                  type: 'UPDATE_SELECTOR',
                  selector: selector,
                  shift: event.shiftKey,
                  ctrl: event.ctrlKey
                }, '*');
            });
            
            window.addEventListener('message', (event)=>{
                if(event.data.type === 'UPDATE_STYLES') {
                    const selector = event.data.selector;
                    setSelector(selector)
                }
            })
            
            // init selector
            setSelector('${this.props.selector || ""}')
        </script>`);
        doc.body.appendChild(interceptLinkClicks);
        // Serialize the DOM object to a string, excluding <script> tags
        const bodyContent = doc.body.innerHTML;
        const headContent = doc.head.innerHTML;

        // Construct the final HTML string manually
        return `
            <!DOCTYPE html>
            <html lang="">
                <head>
                    ${headContent}
                    <title>website preview</title>
                    <style> /*
                        :not(a) {
                          pointer-events: none;
                        }
                        :not(a):has(a) {
                          pointer-events: initial; 
                        } */
                    </style>
                </head>
                <body>
                    ${bodyContent}
                </body>
            </html>
        `
    }


    render() {
        const html = this.state.wrapped_html;
        return <iframe
                ref={this.iframeRef}
                srcDoc={html}
                className={"selector-generator-iframe"}
            />
    }
}

export default HTMLSelectorGenerator;