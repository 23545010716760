import React from 'react'
import {FormControl, FormControlLabel, MenuItem, Select, Stack, Switch, TextField} from "@mui/material";
import {ColumnTypes, IColumnText} from "../../../services/types/columns";
import BaseFieldDefinition, {IIncidentFieldProps} from "./BaseFieldDefinition";
import {cn} from "../../../services/utils";

export default class FieldDefinitionText extends BaseFieldDefinition<IColumnText> {
    constructor(props:IIncidentFieldProps<IColumnText>) {
        super(props);
    }

    render() {
        const column = this.props.column;
        const onEdit = this.props.onEdit;

        return <Stack gap={1} direction={"row"}>
            <FormControl className={"fill-width-util"}>
                <TextField
                    label={"Language (English)"}
                    className={"fill-width-util"}
                    variant="outlined"
                    value={column.translation}
                    InputProps={{
                        readOnly: !onEdit,
                    }}
                    onChange={(e)=>{
                        if(onEdit){
                            column.translation = e.target.value
                            onEdit(column);
                        }
                    }}
                />
            </FormControl>
            <FormControl>
                <Select
                    label="Data Category"
                    variant="outlined"
                    value={column.data_category || 0}
                    className={"fill-width-util"}
                    readOnly={!onEdit}
                    onChange={(e) => {
                        if (onEdit) {
                            column.data_category = e.target.value ? "" + e.target.value : null
                            onEdit(column);
                        }
                    }}
                >
                    <MenuItem value={0}>Generic</MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={"fill-width-util"}>
                <FormControlLabel control={
                    <Switch
                        checked={column.multi_line}
                        onChange={(e)=>{
                            if(onEdit) {
                                column.multi_line = e.target.checked
                                onEdit(column);
                            }
                        }}
                    />
                } label={"Long Text?"}/>
            </FormControl>
        </Stack>
    }
}