import React from 'react'
import {IColumnObject, IColumnText} from "../../../services/types/columns";
import BaseFieldDefinition, {IIncidentFieldProps} from "./BaseFieldDefinition";
import {
    FormControl,
    IconButton,
    Stack,
    styled,
    TextField,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Zoom
} from "@mui/material";
import {AddCircle, RemoveCircle} from "@mui/icons-material"
import FieldDefinition from "./FieldDefinition";

const HtmlTooltip = styled(({ className, children,...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}  children={children}/>
))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
}));

export default class FieldDefinitionObject extends BaseFieldDefinition<IColumnObject> {
    private getNewProperty = (): IColumnText=>{
        return {
            column_type: "TEXT",
            description: "",
            key: "",
            multi_line: false,
            title: "",
            translation: ""
        };
    };
    constructor(props:IIncidentFieldProps<IColumnObject>) {
        super(props);
    }

    render() {
        const column = this.props.column;
        const onEdit = this.props.onEdit;
        const properties = column.properties;

        return <Stack direction={"column"} gap={1}>
            <span>Object Fields:</span>
            {
                <Stack gap={1}>
                    {
                        properties.map((e: any, i: number) => {
                            return <HtmlTooltip
                                TransitionComponent={Zoom}
                                TransitionProps={
                                    {
                                        style: {
                                            transformOrigin: "center left"
                                        }
                                    }
                                }
                                title={
                                    !!onEdit ? <Tooltip title={"הסרת אפשרות"} arrow placement={"bottom"}>
                                        <IconButton
                                            aria-label="delete"
                                            color={"error"}
                                            onClick={()=> {
                                                if(onEdit){
                                                    column.properties.splice(i, 1);
                                                    onEdit(column);
                                                }
                                            }}
                                        >
                                            <RemoveCircle/>
                                        </IconButton>
                                    </Tooltip> : ""
                                }
                                placement={"right-start"}
                                key={i}
                            >
                                <FormControl className={"fill-width-util"}>
                                    <FieldDefinition
                                        column={e}
                                        onEdit={onEdit ? (v) => {
                                            column.properties[i] = v;
                                            if (onEdit) {
                                                onEdit(column)
                                            }
                                        } : null}
                                        depth={this.props.depth + 1}
                                    />
                                </FormControl>
                            </HtmlTooltip>
                        })
                    }
                    {
                        !!this.props.onEdit ?
                            <div><Tooltip title={"הוספת רשומה"} arrow placement={"bottom"}>
                                <IconButton
                                    aria-label="add"
                                    color={"primary"}
                                    onClick={()=>{
                                        if(onEdit){
                                            column.properties.push(this.getNewProperty());
                                            onEdit(column);
                                        }
                                    }}
                                >
                                    <AddCircle/>
                                </IconButton>
                            </Tooltip></div> : null
                    }
                </Stack>
            }
        </Stack>
    }
}