export const cleanDataForEmptyCheck = (v:any)=>{
    if (typeof v === 'string' || v instanceof String){
        return v.toLowerCase().trim()
    }
    return v;
}

export const isEmpty = (v:any, emptyDataIndicators: string[] | null)=> {
    if((!v && v!==0) || !emptyDataIndicators){ return true; }
    const cleanedV = cleanDataForEmptyCheck(v);
    return emptyDataIndicators.includes(cleanedV)
}