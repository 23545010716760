import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import "./settings/settings.scss"
import { MenuItem, Select,} from "@mui/material";
import withLanguage, {ILanguageProps} from "../services/languages/withLanguage";
import {langProperties, Language} from "../services/languages/LanguageContext";

interface IProps extends ILanguageProps {}

interface IState {
}

class Preferences extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    async componentDidMount(){}


    render() {
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    העדפות
                </div>
            </TopNavBar>
            <div className={"page-content content-wrap"}>
                <Select
                    value={this.props.language}
                    onChange={(e)=>{this.props.setLanguage((e.target.value as Language))}}
                >
                    {
                        Object.keys(langProperties).map((l: string, i)=>{
                            const langCode = (l as Language)
                            return <MenuItem value={langCode}>{langProperties[langCode].title}</MenuItem>
                        })
                    }
                </Select>
            </div>
        </div>
    }
}

export default (withLanguage(Preferences));