import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";
import IncidentBrowser from "./incident_browser/IncidentBrowser";
import {
    browseToCurationWorkspace, encodeBrowseWorkspaceSearchParams, encodeCurationSessionWorkspaceSearchParams,
    IBrowseWorkspace,
    parseBrowseWorkspaceSearchParams
} from "../services/types/workspace";

interface IProps extends IRouterProps {}
interface IState {initWorkspace?: IBrowseWorkspace}


class BrowseIncidents extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const workspaceParamStr = props.searchParams.get("s");
        this.state = {
            initWorkspace: parseBrowseWorkspaceSearchParams(workspaceParamStr)
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const workspaceParamStr = this.props.searchParams.get("s");
        if(workspaceParamStr !== prevProps.searchParams.get("s")){
            this.setState((curr)=>({...curr,
                initWorkspace: parseBrowseWorkspaceSearchParams(workspaceParamStr)
            }))
        }
    }

    private goToIncidentPage = (incidentId:number, workspace:IBrowseWorkspace) => {
        const workspaceParam = encodeCurationSessionWorkspaceSearchParams(browseToCurationWorkspace(workspace, incidentId));
        this.props.navigate({
            pathname: "/incident/",
            search: "s="+workspaceParam,
        });
    }

    private setWorkspaceConfig = (workspace: IBrowseWorkspace) => {
        const newUrl = `${window.location.pathname}?s=${encodeBrowseWorkspaceSearchParams(workspace)}`;
        const currentUrl = window.location.href;
        if(newUrl !== currentUrl) {
            window.history.pushState({}, '', newUrl);
        }
    }

    render() {
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    תקריות
                </div>
            </TopNavBar>
            <div className={"page-content content-wrap"}>
                <IncidentBrowser
                    initWorkspace={this.state.initWorkspace}
                    onWorkspaceChange={(workspace)=>{
                        this.setWorkspaceConfig(workspace)
                    }}
                    onClick={this.goToIncidentPage}
                />
            </div>
        </div>
    }
}

export default (withRouter(BrowseIncidents));