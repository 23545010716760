import React from 'react'
import {IBaseColumn} from "../../../services/types/columns";

export interface IIncidentFieldProps<X extends IBaseColumn> {
    depth: number,
    column: X,
    onEdit: null | ((value: any)=>void),
    isArrayEntry?: boolean
}
interface IState {
    expanded: boolean
}



export default class BaseFieldDefinition<X extends IBaseColumn> extends React.Component<IIncidentFieldProps<X>, IState> {
    constructor(props: IIncidentFieldProps<X>) {
        super(props);
        this.state = {
            expanded: props.depth === 0
        }
    }

    async componentDidMount() {}


    render() {
        return <div>no column type selected</div>
    }
}