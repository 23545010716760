import React from 'react'
import "./scrape/Scrape.scss"
import {
    Button,
    CircularProgress, LinearProgress,
    Stack,
    Tooltip,
} from "@mui/material";
import server from "../services/server";
import TopNavBar from "../UIComponents/TopNavBar/TopNavBar";
import {Cancel, Stop} from "@mui/icons-material";

interface IProps {}
interface IState {
    jobId: null | number,
    awaitingFirstJobStatusCheck: boolean,
    awaitingJobLoad: boolean,
    awaitingScrapeInitiation: boolean,
    taskStatus: null | ITask,
    taskStatusCheckInterval:  NodeJS.Timeout
}

interface ITask {
    id: number,
    job_id: number,
    task_title: string,
    task_size: number,
    task_progress: number,
    task_curr: string,
    task_status: string
}


export default class Scrape extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            jobId: null,
            taskStatus: null,
            awaitingFirstJobStatusCheck: true,
            awaitingJobLoad: false,
            awaitingScrapeInitiation: false,
            taskStatusCheckInterval: setInterval(async ()=>{await this.checkScrapingStatus()}, 1000)
        }
    }

    async componentDidMount() {}

    private async checkScrapingStatus() {
        if(this.state.awaitingJobLoad){return}
        this.setState((current)=>({...current, awaitingJobLoad: true}), async ()=>{
            const res = await server.get("scrape/status/");
            if(res.error || res.task_status === null){
                this.setState((current)=>({...current, awaitingJobLoad: false, awaitingFirstJobStatusCheck: false, jobId: null}))
                return;
            }
            const jobId = res.job_id;
            const taskStatus: ITask = res.task_status;
            this.setState((current)=>({...current, taskStatus, awaitingJobLoad: false, awaitingFirstJobStatusCheck: false}))
        })
    }

    private initiateScraping() {
        if(this.state.awaitingScrapeInitiation){return}
        this.setState((current)=>({...current, awaitingScrapeInitiation: true}), async ()=>{
            const res = await server.post("scrape/", {});
            if(res.error){
                this.setState((current)=>({...current, awaitingScrapeInitiation: false, jobId: null}))
                return;
            }
            const jobId = res.job_id;
            this.setState((current)=>({...current, jobId, awaitingScrapeInitiation: false}))
        })
    }


    private async haltJob() {
        if(this.state.jobId === null){ return }
        const res = await server.post("scrape/cancel/", {job_id: this.state.jobId});
    }

    render() {
        const jobId = this.state.jobId;
        const taskStatus = this.state.taskStatus;
        const currTaskStep = taskStatus?.task_curr || "";
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    <span className="title-lang">איסוף נתונים</span>
                </div>
            </TopNavBar>
            <div className={"page-content"}>
                <div className={"scraping-manager-wrap"}>
                {
                    !this.state.awaitingFirstJobStatusCheck ?
                        jobId !== null ?
                            <div className={"task-status-wrap"}>{
                                taskStatus !== null ?
                                    <div className={"task-status"}>
                                        <Stack
                                            direction={"row"}
                                            gap={2} dir={"ltr"}
                                            alignItems={"center"}>
                                            <CircularProgress/>
                                            <h1
                                                className={"task-title"}>
                                                {taskStatus.task_title}
                                            </h1>
                                            <Tooltip title={"Cancel Scraping Job"} arrow>
                                            <Button
                                                onClick={async ()=>{
                                                    await this.haltJob()
                                                }}
                                                title={"cancel scraping job"}
                                                color={"error"}
                                                variant={"contained"}
                                            >
                                                <Cancel/>
                                            </Button>
                                            </Tooltip>
                                        </Stack>
                                        <LinearProgress
                                            className={"task-progress"}
                                            variant="determinate"
                                            value={taskStatus.task_progress / taskStatus.task_size * 100}
                                        />
                                        <p className={"task-step-description"}>{
                                            currTaskStep.trim()
                                        }</p>
                                    </div> :
                                    <CircularProgress/>
                            }</div> :
                            (
                                this.state.awaitingScrapeInitiation ?
                                    <CircularProgress/> :
                                    <Button
                                        variant="contained"
                                        onClick={()=>{this.initiateScraping()}}
                                    >
                                        Scrape
                                    </Button>
                            )
                        : <CircularProgress/>
                }
                </div>
            </div>
        </div>
    }
}