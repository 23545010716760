import React from 'react';
import {IColumnNumber} from "../../../services/types/columns";
import BaseFieldDefinition, {IIncidentFieldProps} from "./BaseFieldDefinition";

export default class FieldDefinitionNumber extends BaseFieldDefinition<IColumnNumber> {
    constructor(props:IIncidentFieldProps<IColumnNumber>) {
        super(props);
    }

    render() {
        return <React.Fragment/>;
    }
}