import React from 'react';
import { Language, LanguageContext } from './LanguageContext'; // Adjust path as needed

export interface ILanguageProps {
    language: Language;
    setLanguage: (language: Language) => void;
}

const withLanguage = <P extends object>(WrappedComponent: React.ComponentType<P & ILanguageProps>) => {
    return class WithLanguage extends React.Component<Omit<P, keyof ILanguageProps>, any> {
        static contextType = LanguageContext;
        context!: React.ContextType<typeof LanguageContext>;

        render() {
            if (!this.context) {
                throw new Error('withLanguage must be used within a LanguageProvider');
            }

            const { language, setLanguage } = this.context;
            return <WrappedComponent {...(this.props as P)} language={language} setLanguage={setLanguage} />;
        }
    };
};

export default withLanguage;
