import React from 'react'
import {Divider, IconButton, Stack, styled, Tooltip, tooltipClasses, TooltipProps, Zoom} from "@mui/material";
import {
    IColumn,
    IColumnArray,
    IColumnObject,
} from "../../../services/types/columns";
import BaseIncidentField from "./BaseIncidentField";
import Paper from "@mui/material/Paper";
import IncidentField from "./IncidentField";
import {AddCircle, RemoveCircle} from "@mui/icons-material";

const HtmlTooltip = styled(({ className, children,...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}  children={children}/>
))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
}));

export default class IncidentFieldArray extends BaseIncidentField<IColumnArray> {
    private generateDefaultValue = (column: IColumn) => {
        switch (column.column_type) {
            case "TEXT":
                return ""
            case "NUMBER":
                return 0
            case "CITY":
                return ""
            case "SELECT":
                return null
            case "DATE":
                return new Date()
            case "OBJECT":
                const emptyObject:{[key: string]: any} = {};
                (column as IColumnObject).properties.forEach(p => {
                    emptyObject[p.key] = this.generateDefaultValue(p)
                })
                return emptyObject;
            case "ARRAY":
                return []
        }
        return null
    }

    private addRow = () => {
        const data: any[] = this.props.data || [];
        const entry = this.generateDefaultValue(this.props.column.entries)
        data.push(entry)
        this.props.onEdit?.(data, "$");
    };

    private deleteRow(i: number) {
        const data: any[] = this.props.data || [];
        data.splice(i, 1);
        this.props.onEdit?.(data, "$");
    }

    render() {
        const column = this.props.column;
        const data = this.props.data || [];
        const sources = this.props.sources;
        const emptyChecker = this.props.emptyChecker;
        const onSourcesClick = this.props.onSourcesClick;
        const onEdit = this.props.onEdit;
        const onClick = this.props.onClick;
        const labelOverride = this.props.labelOverride

        return <div className={"card-data-entries-wrap wide-field"}>
            <Stack gap={1}

                   onClick={()=>{
                       if(onClick){
                           onClick(data);
                       }
                   }}
            >
            <Stack gap={1} direction={"row"}>
                <span>{labelOverride !== undefined ? labelOverride : column.title}</span>
                {this.getLabelSourcesAdornment(column, sources, onSourcesClick)}
            </Stack>
            <Stack gap={1}>
                {
                    data.map((e: any, i: number) => {
                        return <HtmlTooltip
                            TransitionComponent={Zoom}
                            TransitionProps={
                                {
                                    style: {
                                        transformOrigin: "center left"
                                    }
                                }
                            }
                            title={
                                !!onEdit ? <Tooltip title={"הסרת רשומה"} arrow placement={"bottom"}>
                                    <IconButton
                                        aria-label="delete"
                                        color={"error"}
                                        onClick={()=> {this.deleteRow(i)}}
                                    >
                                        <RemoveCircle/>
                                    </IconButton>
                                </Tooltip> : ""
                            }
                            placement={"right-start"}
                            key={i}
                        >
                            <Paper className={"array-entry-wrap wide-field"}>
                            <IncidentField
                                column={column.entries}
                                data={e}
                                citation={null}
                                sources={null}
                                onEdit={onEdit ? (v, f) => {
                                    const inner_field = f ? "$>" + f : f;
                                    data[i] = v;
                                    if (onEdit) {
                                        onEdit(data, inner_field)
                                    }
                                } : null}
                                onClick={null}
                                onSourcesClick={null}
                                emptyChecker={emptyChecker}
                            />
                            {i !== data.length - 1 ?
                                <Divider orientation="vertical" flexItem/>
                                : null
                            }
                        </Paper>
                        </HtmlTooltip>
                    })
                }
                {
                    !!this.props.onEdit ?
                        <div><Tooltip title={"הוספת רשומה"} arrow placement={"bottom"}>
                            <IconButton
                                aria-label="add"
                                color={"primary"}
                                onClick={this.addRow}
                            >
                                <AddCircle/>
                            </IconButton>
                        </Tooltip></div> : null
                }
            </Stack>
            </Stack>
        </div>
    }
}