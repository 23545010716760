import React, {Component} from 'react';
import {Button, Snackbar, SnackbarContent, Stack} from '@mui/material';
import {
    IMergeNotification,
    INCIDENT_ACTIONS,
    IncidentNotification, ISplitNotification,
    IStatusChangeNotification, Notification, NOTIFICATION_TYPE,
    NotificationContext
} from './NotificationsContext';
import {setIncidentApprovalStatus} from "../../services/incidentManager";
import withNotifications, {WithNotificationProps} from "./withNotifications";
import {
    encodeCurationSessionWorkspaceSearchParams,
    ICurationSessionWorkspace
} from "../../services/types/workspace";
import withRouter, {IRouterProps} from "../../services/withRouter";

type IProps = {} & WithNotificationProps & IRouterProps;
class NotificationsDisplay extends Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    renderNotification = (notification: IncidentNotification,
                          removeNotification: (id: number) => void) => {
        const actions: JSX.Element[] = []
        const notificationType = notification?.actionType;
        let title = null
        switch (notificationType) {
            case INCIDENT_ACTIONS.MERGE:
                const notificationAsMerge = (notification as IMergeNotification);
                //actions.push(<Button>Undo</Button>); TODO: implement undo merge
                actions.push(<Button
                    onClick={()=>{
                        this.goToIncidentPage(notificationAsMerge.mergedInto);
                    }}
                >
                    Go to merged incident
                </Button>)
                title = "" + (notification as IMergeNotification).mergedIncidents.length + " incidents merged"
                break;
            case INCIDENT_ACTIONS.SPLIT:
                const notificationAsSplit = (notification as ISplitNotification);
                // actions.push(<Button>Undo</Button>); TODO: implement undo merge
                (notification as ISplitNotification).splitInto.forEach((incident, i) => {
                    actions.push(<Button
                        onClick={() => {
                            this.goToIncidentPage(incident);
                        }}
                    >
                        incident {(i + 1)}
                    </Button>)
                })
                title = "Incident reports split"
                break;
            case INCIDENT_ACTIONS.SAVE:
                // actions.push(<Button>Undo</Button>); TODO: implement undo save
                title = "Incident data saved"
                break;
            case INCIDENT_ACTIONS.STATUS_CHANGE:
                const notificationDetails = (notification as IStatusChangeNotification);
                actions.push(<Button
                    onClick={async () => {
                        await setIncidentApprovalStatus(notificationDetails.prevStatus);
                        if(notificationDetails.id) {
                            this.props.notificationContext.removeNotification(notificationDetails.id);
                        }
                        notificationDetails.onUndo(
                            notificationDetails.incidentIds,
                            notificationDetails.newStatus,
                            notificationDetails.prevStatus
                        )
                    }}
                >
                    Undo
                </Button>)
                title = "Switch incident status from " + (notification as IStatusChangeNotification).prevStatus +
                    " to " + (notification as IStatusChangeNotification).newStatus
                break;
            default:
                break;
        }
        if (notification?.title) {
            title = notification.title
        }
        return notification ? <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            dir={"rtl"}
            key={notification?.id}
            open
            autoHideDuration={notification?.autoHideDuration}
            onClose={() => {
                if (notification && notification.id !== undefined) {
                    removeNotification(notification.id)
                }
            }}
        >
            <SnackbarContent
                message={title}
                action={<Stack direction={"row"} gap={1} dir={"ltr"}>
                    {
                        actions.map((a, i)=>{
                            return <React.Fragment key={i}>
                                {a}
                            </React.Fragment>
                        })
                    }
                </Stack>}
            />
        </Snackbar> : null
    }

    private goToIncidentPage(incidentId: number) {
        const workspace: ICurationSessionWorkspace = {
            columnFilterFns: {}, columnFilters: [], globalFilter: "", sorting: [], status: null, incidentId
        }
        const workspaceParam = encodeCurationSessionWorkspaceSearchParams(workspace);
        this.props.navigate({
            pathname: "/incident/",
            search: "s="+workspaceParam,
        });
    }

    render() {
        return (
            <NotificationContext.Consumer>
                {(context) => {
                    if (!context) {
                        return null; // or some fallback UI
                    }

                    const {notifications, removeNotification} = context;

                    return (
                        <>
                            {notifications.map((notification: Notification) => {
                                    if (!notification) {
                                        return null
                                    }
                                    if (notification.notificationType === NOTIFICATION_TYPE.INCIDENT_ACTION) {
                                        return this.renderNotification((notification as IncidentNotification), removeNotification)
                                    }
                                    return null
                                }
                            )}
                        </>
                    )
                }}
            </NotificationContext.Consumer>
        );
    }
}

export default withRouter(withNotifications<IProps>(NotificationsDisplay));
