import React from 'react'

import "./IncidentBrowser.scss"

import server from "../../services/server";
import {IColumnArray, IColumnObject,} from "../../services/types/columns";
import {CircularProgress} from "@mui/material";
import IncidentTable from "./IncidentTable";
import {IBrowseWorkspace} from "../../services/types/workspace";
import AlignDir from "../../services/languages/AlignDir";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";

interface IProps {
    initWorkspace?: IBrowseWorkspace,
    onClick: ((incidentId:number, workspace:IBrowseWorkspace)=>any) | null,
    onWorkspaceChange?: (workspace:IBrowseWorkspace)=>any
}


interface IState {
    initWorkspace?: IBrowseWorkspace,
    columnSchema: IColumnObject | null,
    emptyDataIndicators: string[] | null,
    workspaceReady: boolean,
}


class IncidentBrowser extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            columnSchema: null,
            emptyDataIndicators:  null,
            workspaceReady: false,
            initWorkspace: props.initWorkspace
        }
    }

    async componentDidMount() {
        await this.fetchColumns();
        await this.fetchEmptyDataIndicators();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(this.props.initWorkspace !== prevProps.initWorkspace){
            this.setState((curr)=>({...curr, initWorkspace: this.props.initWorkspace}));
        }
    }

    private async fetchColumns() {
        const res = await server.get("columns/");
        if (res && !res.error) {
            const columnSchema = (((res as IColumnObject).properties[0] as IColumnArray).entries as IColumnObject);
            this.setState((current) => ({...current, columnSchema}))
        }
    }

    async fetchEmptyDataIndicators() {
        const res = await server.get("null-data-flags/");
        if (res && !res.error) {
            this.setState((current) => ({
                ...current,
                emptyDataIndicators: res.map((x:{flag:string})=>x.flag)
            }))
        }
    }

    render() {
        const columnSchema = this.state.columnSchema;
        const emptyDataIndicators = this.state.emptyDataIndicators?.slice();

        return (columnSchema && emptyDataIndicators) ?
            <AlignDir direction={"ltr"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <IncidentTable
                    columnSchema={columnSchema}
                    emptyDataIndicators={emptyDataIndicators}
                    onClick={this.props.onClick}
                    initWorkspace={this.state.initWorkspace}
                    onWorkspaceChange={(workspace)=>{
                        if(this.props.onWorkspaceChange) {
                            this.props.onWorkspaceChange(workspace)
                        }
                    }}
                />
                </LocalizationProvider>
            </AlignDir> : <CircularProgress/>
    }
}

export default IncidentBrowser;