import React from 'react'
import {Badge, Tooltip} from "@mui/material";
import {IBaseColumn, IColumn,} from "../../../services/types/columns";
import {IIncidentReport} from "../../../services/types/incident";
import {getDistinctReportsCount} from "../../../services/incidentManager";
import {ISourcesPopoverData} from "../IncidentViewer";

export interface IIncidentFieldProps<X extends IBaseColumn> {
    column: X,
    data: any,
    citation: null | string,
    sources: null | IIncidentReport[],
    onEdit: null | ((value: any, field: string | null)=>void),
    onClick: null | ((value: any)=>void),
    onSourcesClick: null | expandSourcesHandler,
    emptyChecker: (v:any) => boolean,
    labelOverride?: JSX.Element
}
interface IState {}

export type expandSourcesHandler = (
    sourcesPopoverAnchor: HTMLElement,
    sourcesPopoverData: ISourcesPopoverData
) => void


export default class BaseIncidentField<X extends IBaseColumn> extends React.Component<IIncidentFieldProps<X>, IState> {
    constructor(props: IIncidentFieldProps<X>) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {}

    getLabelSourcesAdornment = (x: IColumn, reports: IIncidentReport[] | null, onExpand: expandSourcesHandler | null) => {
        if(reports === null){ return null }
        const data = reports.map(r=>r.data);
        const distinctReportsCount = getDistinctReportsCount(data, x, this.props.emptyChecker);
        const adornmentColor = (distinctReportsCount > 1) ?
            (x.column_type === "TEXT" ? "primary" : "error") :
            "success"
        return <Tooltip
            title={"מקורות: " + reports.length + " דיווחים, " + distinctReportsCount + " גרסאות"}
            arrow placement={"bottom"}
            dir={"rtl"}
        >
            <button
                onClick={(e)=>{
                    const anchor = (e.currentTarget as HTMLElement);
                    if (onExpand && reports !== null) {
                        onExpand(anchor, {
                            column: x,
                            data: reports,
                            onSelect: (v:IIncidentReport) => {
                                this.props.onEdit?.(JSON.parse(JSON.stringify(v)), null);
                            }
                        });
                    }
                }}
            >
                <Badge
                    badgeContent={""}
                    color={adornmentColor}
                />
            </button>
        </Tooltip>
    }

    render() {
        return <div>no column type selected</div>
    }
}